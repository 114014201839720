var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.redirectMode == 'link' ? 'router-link' : 'button',{tag:"component",staticClass:"chat-conversation",attrs:{"to":_vm.redirectMode == 'link' ? { name: 'chats__single', params: { id: _vm.chat._id } } : null,"active-class":"chat-conversation--active"},on:{"click":_vm.onButtonClick}},[_c('div',{staticClass:"chat-conversation__wrapper rounded",class:{
            'indigo lighten-5':
                _vm.$route.name == 'chats__details' && _vm.$route.params.id === _vm.chat._id,
            'grey lighten-3': _vm.chat.unread_count > 0
        }},[_c('div',{staticClass:"chat-conversation__avatar"},[_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"success","dot":"","offset-x":"10","offset-y":"10","value":_vm.debtor_loaded && _vm.debtor.activity_status == 'online'}},[_c('v-avatar',{staticClass:"white--text",attrs:{"color":"indigo"}},[(!_vm.debtor_loaded)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"16","width":"2"}}):[_vm._v(" "+_vm._s(_vm.debtor_initials)+" ")]],2)],1)],1),_c('div',{staticClass:"chat-conversation__details"},[_c('div',{staticClass:"chat-conversation__name",class:{
                    'font-weight-medium': _vm.chat.unread_count > 0
                }},[(!_vm.debtor_loaded)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):[_vm._v(" "+_vm._s(_vm.debtor_loaded ? _vm.debtor.name : "")+" "),_c('span',{staticClass:"text-caption"},[_vm._v(" ("+_vm._s(_vm.chat.application.service_provider)+"/"+_vm._s(_vm.chat.application.number)+") ")])]],2),_c('div',{staticClass:"chat-conversation__subline text-caption",class:{
                    'font-weight-medium grey--text text--darken-2': _vm.chat.unread_count > 0
                }},[_c('div',{staticClass:"chat-conversation__lm"},[_vm._v(_vm._s(_vm.latest_chat_message_text))]),_c('div',{staticClass:"chat-conversation__sdot"},[_vm._v("·")]),_c('div',{staticClass:"chat-conversation__date"},[_vm._v(" "+_vm._s(_vm.la_date_formatted)+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }