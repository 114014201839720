var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(_vm.onIntersect),expression:"onIntersect",modifiers:{"once":true}}],staticClass:"chat-message",class:{
        'chat-message--right': _vm.createdByCurrentUser,
        'chat-message--ficg': _vm.isFirstInCreatorGroup,
        'chat-message--licg': _vm.isLastInCreatorGroup
    }},[_c('div',{staticClass:"chat-message__content",class:{
            'chat-message__content--padded': !_vm.createdByCurrentUser
        }},[_c('div',{staticClass:"chat-message__msg",class:{ 'chat-message__msg--mine': _vm.createdByCurrentUser }},[_c('div',{staticClass:"chat-message__msgc",class:{
                    'chat-message__msgc--mine': _vm.createdByCurrentUser,
                    'chat-message__msgc--not-mine': !_vm.createdByCurrentUser,
                    'chat-message__msgc--first-in-group': _vm.isFirstInCreatorGroup,
                    'chat-message__msgc--last-in-group': _vm.isLastInCreatorGroup
                },attrs:{"title":_vm.formatDateWithTime(new Date(_vm.item.c_date))},on:{"click":function($event){_vm.$device.mobile ? (_vm.mobile_details = !_vm.mobile_details) : false}}},[(_vm.item.message && _vm.item.message.length > 0)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.message.nl2br())}}):(_vm.item.attachment)?_c('div',{staticClass:"chat-message-file",on:{"click":function($event){$event.stopPropagation();return _vm.openAttachmentPreview.apply(null, arguments)}}},[_c('div',{staticClass:"chat-message-file__icon"},[(_vm.file_thumb_url === null)?_c('div',{staticClass:"chat-message-file__phicon"},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v("mdi-file")])],1):_c('v-img',{staticClass:"chat-message-file__image",attrs:{"src":_vm.file_thumb_url}})],1),_c('div',{staticClass:"chat-message-file__details",attrs:{"title":_vm.item.attachment.file_name}},[_vm._v(" "+_vm._s(_vm.item.attachment.file_name)+" "),_c('div',{staticClass:"chat-message-file__size text-caption grey--text"},[_vm._v(" "+_vm._s(_vm.uploading_file ? "Przesyłanie pliku..." : _vm.formatFileSize(_vm.item.attachment.file_size))+" ")]),(_vm.uploading_file)?_c('v-progress-linear',{staticClass:"mt-1",attrs:{"height":"4","color":"primary","value":_vm.item.upload_progress}}):_vm._e()],1)]):_vm._e()])]),(_vm.item.attachment != undefined)?_c('div',{staticClass:"chat-message__attdownload",class:{
                'chat-message__attdownload--left': _vm.createdByCurrentUser,
                'chat-message__attdownload--right': !_vm.createdByCurrentUser
            }},[_c('v-btn',{attrs:{"icon":"","color":"grey lighten-1","loading":_vm.downloading_attachment},on:{"click":_vm.downloadAttachment}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1):_vm._e(),(!_vm.createdByCurrentUser && _vm.item.hide_avatar !== true && _vm.isLastInCreatorGroup)?_c('div',{staticClass:"chat-message__user"},[_c('v-avatar',{attrs:{"size":"28","color":"primary","title":_vm.message_sender ? _vm.message_sender.name : ''}},[(!_vm.message_sender)?_c('v-progress-circular',{attrs:{"color":"white","width":"1","size":"12","indeterminate":""}}):[(
                            !_vm.message_sender.profile_image ||
                            !_vm.message_sender.profile_image.s3_file_url_128px ||
                            _vm.message_sender.profile_image.s3_file_url_128px === ''
                        )?_c('div',{staticClass:"text-center text-caption text-uppercase white--text",staticStyle:{"user-select":"none"}},[_vm._v(" "+_vm._s(_vm.$store.getters["users/getInitialsFromName"](_vm.message_sender.name))+" ")]):_c('v-img',{attrs:{"src":_vm.message_sender.profile_image.s3_file_url_128px,"width":"100%","height":"100%"}})]],2)],1):_vm._e(),_c('div',{staticClass:"chat-message__indicator",class:{
                'chat-message__indicator--right': _vm.createdByCurrentUser,
                'chat-message__indicator--left': !_vm.createdByCurrentUser
            }},[(_vm.createdByCurrentUser && _vm.item.status == 'created')?_c('span',{attrs:{"title":"Wysyłanie"}},[_c('v-icon',{attrs:{"size":"16","color":"secondary"}},[_vm._v("mdi-circle-outline")])],1):(_vm.createdByCurrentUser && _vm.item.status == 'sent')?_c('span',{attrs:{"title":"Wiadomość wysłana"}},[_c('v-icon',{attrs:{"size":"16","color":"secondary"}},[_vm._v("mdi-check-circle-outline")])],1):(_vm.createdByCurrentUser && _vm.item.status == 'delivered')?_c('span',{attrs:{"title":"Wiadomość dostarczona"}},[_c('v-icon',{attrs:{"size":"16","color":"secondary"}},[_vm._v("mdi-check-circle")])],1):(
                    _vm.createdByCurrentUser &&
                    _vm.item.status == 'read' &&
                    _vm.isLastInCreatorGroup &&
                    _vm.item.newest_read_of_its_creator
                )?_c('span',{attrs:{"title":'Wyświetlono - ' + _vm.formatDateWithTime(new Date(_vm.item.read_date))}},[_c('v-avatar',{attrs:{"size":"16","color":"primary","title":_vm.chat_recipient ? _vm.chat_recipient.name : ''}},[(!_vm.chat_recipient)?_c('v-progress-circular',{attrs:{"color":"white","width":"1","size":"8","indeterminate":""}}):[(
                                !_vm.chat_recipient.profile_image ||
                                !_vm.chat_recipient.profile_image.s3_file_url_128px ||
                                _vm.chat_recipient.profile_image.s3_file_url_128px === ''
                            )?_c('div',{staticClass:"text-center text-uppercase white--text font-weight-light",staticStyle:{"user-select":"none","font-size":"8px","line-height":"8px"}},[_vm._v(" "+_vm._s(_vm.$store.getters["users/getInitialsFromName"](_vm.chat_recipient.name))+" ")]):_c('v-img',{attrs:{"src":_vm.chat_recipient.profile_image.s3_file_url_128px,"width":"100%","height":"100%"}})]],2)],1):_vm._e()])]),_c('v-expand-transition',[(_vm.mobile_details)?_c('div',[_c('div',{staticClass:"chat-message__msgd text-caption grey--text text-right",class:{ 'chat-message__msgd--padded': !_vm.createdByCurrentUser }},[_vm._v(" "+_vm._s(_vm.formatDateWithTime(new Date(_vm.item.c_date)))+" ")])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }