import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pl from 'vuetify/lib/locale/pl';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#BF934F",
                secondary: "#192D4C"
            }
        }
    },
    lang: {
        locales: {
            pl
        },
        current: 'pl'
    }
});
