var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"appkan-card",attrs:{"to":{
        name: 'applications__edit',
        params: {
            id: _vm.application._id
        }
    }}},[_c('v-card',{staticClass:"appkan-card__body pa-2",attrs:{"flat":""}},[(_vm.application.notifications_count)?_c('v-chip',{staticClass:"mt-n1",attrs:{"color":"error","x-small":""}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-bell-alert")]),_vm._v(" "+_vm._s(_vm.$store.getters["pluralize"]({ n: _vm.application.notifications_count, values: ["", "1 powiadomienie", "{n} powiadomienia", "{n} powiadomień"] }))+" ")],1):_vm._e(),_c('div',{staticClass:"font-weight-medium text-body-2"},[_vm._v(" "+_vm._s(_vm.application.service_provider)+"/"+_vm._s(_vm.application.number)+" ")]),_c('div',{staticClass:"text-caption grey--text",staticStyle:{"margin-top":"-2px"}},[(!_vm.debtor_loaded)?_c('v-skeleton-loader',{attrs:{"type":"text","max-width":"80%"}}):[_vm._v(" "+_vm._s(_vm.debtor.name)+" ")]],2),_c('div',{staticClass:"mt-1"},[(!_vm.laywer_loaded)?_c('v-skeleton-loader',{attrs:{"type":"text"}}):_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"16","color":"primary"}},[(
                            _vm.lawyer.profile_image && _vm.lawyer.profile_image.s3_file_url_128px != ''
                        )?_c('v-img',{attrs:{"src":_vm.lawyer.profile_image.s3_file_url_128px}}):_c('div',{staticClass:"text-center white--text font-weight-regular",staticStyle:{"font-size":"8px","line-height":"8px"}},[_vm._v(" "+_vm._s(_vm.$store.getters["auth/userInitials"](_vm.lawyer))+" ")])],1),_c('div',{staticClass:"text-caption grey--text"},[_vm._v(" "+_vm._s(_vm.lawyer.name)+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }