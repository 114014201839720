import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/scss/index.scss";

/**************
 * VUE META *
 ***************/
import VueMeta from "vue-meta";
Vue.use(VueMeta);

/**************
 * AXIOS *
 ***************/
import axios from "./axios";
Vue.use(axios);

/****************
 * DEVICE *
 *****************/
import device from "vue-device-detector";
Vue.use(device);

/**************
 * SOCKETS *
 ***************/
import sockets from "./plugins/socket-io";
Vue.use(sockets);

/**************
 * MESSAGES *
 ***************/
import messages from "./plugins/messages";
Vue.use(messages);

/**************
 * CLIPBOARD.js *
 ***************/
import VueClipboard from "vue-clipboard2";
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

/**************
 * CROPPER.JS *
 ***************/
import "cropperjs/dist/cropper.min.css";

/***********
 * VUE BAR *
 **********/
import Vuebar from "vuebar";
Vue.use(Vuebar);

/**************
 * LIGHTBOX *
 ***************/
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

Vue.use(CoolLightBox);

/******************
 * CLIENT HELPERS *
 *******************/
if (String) {
    String.prototype.nl2br = function (isXhtml) {
        const breakTag = isXhtml || typeof isXhtml === "undefined" ? "<br " + "/>" : "<br>";
        return (this + "").replace(/(\r\n|\n\r|\r|\n)/g, breakTag + "$1");
    };
    String.prototype.pad = function (n) {
        let str = "";
        for (let i = 1; i <= n; i++) {
            str += "0";
        }

        return (str + this).slice(-n);
    };
}
if (Number) {
    Number.prototype.pad = function (digits = 1) {
        let pad = "";
        for (let i = 0; i < digits; i++) {
            pad += "0";
        }

        return (pad + this.toString()).slice(-digits);
    };
}
if (Date) {
    Date.prototype.getWeek = function () {
        var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
        var dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    };
    Date.prototype.print = function (pattern = "DD-MM-YYYY") {
        const map = {
            mm: this.getMonth() + 1,
            dd: this.getDate(),
            yyyy: this.getFullYear().toString().slice(-2),
            YYYY: this.getFullYear(),
            MM: ("0" + (this.getMonth() + 1)).slice(-2),
            DD: ("0" + this.getDate()).slice(-2),
            HH: ("0" + this.getHours()).slice(-2),
            MIN: ("0" + this.getMinutes()).slice(-2),
            hh: this.getHours(),
            min: this.getMinutes()
        };
        return pattern.replace(/mm|dd|yyyy|MM|DD|YYYY|HH|MIN|hh|min/gi, matched => map[matched]);
    };
}

/*********************
 * GLOBAL COMPONENTS *
 **********************/
import Layout from "./components/Layout";
Vue.component("Layout", Layout);

import UserSelect from "./components/UserSelect";
Vue.component("UserSelect", UserSelect);

import UserTile from "./components/UserTile";
Vue.component("UserTile", UserTile);

/*********************
 * GLOBAL LAYOUTS *
 **********************/
import Default from "./layouts/Default";
Vue.component("Default", Default);

import Auth from "./layouts/Auth";
Vue.component("Auth", Auth);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    beforeCreate: async () => {
        // TO NA SAMYM POCZĄTKU SIĘ DZIEJE - staramy się zalogować użytkownika z LS przed pierwszym procesowaniem przez router
        try {
            if (window.localStorage) {
                const ad = JSON.parse(localStorage.getItem("auth"));
                if (
                    ad &&
                    ad.token &&
                    ad.token != "" &&
                    ad.refresh_token &&
                    ad.refresh_token != "" &&
                    ad.token_exp_date &&
                    !isNaN(ad.token_exp_date) &&
                    ad.token_exp_date > 0 &&
                    ad.refresh_token_exp_date &&
                    !isNaN(ad.refresh_token_exp_date) &&
                    ad.refresh_token_exp_date > 0
                ) {
                    // jeżeli refresh_token się nie przeterminował to można auth data podłożyć do store - axios już sobie odświeży co trzeba podczas pobierania danych użytkownika
                    if (ad.refresh_token_exp_date > Date.now() + 120000) {
                        //refresh_token jest uznawany za ważny, jeżeli ma jeszcze chociaż 120s czasu życia
                        await store.dispatch("auth/logIn", ad);
                    }
                }
            }
        } catch (err) {
            console.error("App boot error:");
            console.error(err);
        }

        // oznaczenie, że apka jest gotowa do pracy
        store.state.app_booted = true;
    },
    render: h => h(App)
}).$mount("#app");
