<template>
    <div class="default-layout">
        <v-navigation-drawer
            app
            v-model="sidemenu"
        >
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6"> PanelPomocy.pl </v-list-item-title>
                    <v-list-item-subtitle> Strefa administracyjna </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list
                dense
                nav
            >
                <v-list-item
                    v-for="item in filteredNavItems"
                    :key="item.title"
                    :to="item.to"
                    :exact="item.exact === true"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app>
            <v-app-bar-nav-icon @click="sidemenu = !sidemenu">
                <v-icon>{{ sidemenu ? "mdi-arrow-collapse-left" : "mdi-menu" }}</v-icon>
            </v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <ChatDropdownList />
            <NotificationsList />
            <UserTile
                :user="user"
                tag="router-link"
                :to="{
                    name: 'my-account__main'
                }"
                details-key="role"
                hide-name-details
                class="ml-2"
            />
            <v-btn
                icon
                small
                class="ml-2"
                title="Wyloguj"
                @click="$store.dispatch('auth/logOut')"
            >
                <v-icon :size="20">mdi-logout</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <v-container fluid>
                <transition
                    :name="
                        $route.name != 'chats__list' && $route.name != 'chats__single'
                            ? 'fade'
                            : 'none'
                    "
                    mode="out-in"
                >
                    <div
                        class="pa-0 pa-md-3"
                        :key="$route.fullPath"
                    >
                        <slot />
                    </div>
                </transition>
            </v-container>

            <div
                v-if="!hide_chat_popups"
                class="default-layout-chpopups"
            >
                <div
                    class="default-layout-chpopups__item"
                    v-for="chat_id in $store.state.chats.opened_chats_popup_windows"
                    :key="chat_id"
                >
                    <ChatPopupWindow :chat-id="chat_id" />
                </div>
            </div>

            <!-- CHAT FILE PREVIEW -->
            <v-scale-transition>
                <div
                    class="default-layout-chfpr"
                    v-if="chat_file_preview"
                >
                    <div class="default-layout-chfpr__close">
                        <v-btn
                            icon
                            color="white"
                            @click="closeFilePreview"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                    <div class="default-layout-chfpr__vp">
                        <div
                            class="default-layout-chfpr__loader"
                            v-if="chat_file_preview_loading"
                        >
                            <v-progress-circular
                                indeterminate
                                size="36"
                                width="2"
                                color="primary"
                            ></v-progress-circular>
                            <div class="mt-2">Ładowanie podglądu pliku...</div>
                        </div>
                        <div
                            class="default-layout-chfpr__err"
                            v-else-if="chat_file_preview_url == null"
                        >
                            Nie udało się załadować podglądu pliku
                        </div>
                        <template v-else>
                            <v-img
                                v-if="chat_file_preview_type == 'image'"
                                :src="chat_file_preview_url"
                                alt=""
                                class="default-layout-chfpr__image"
                                contain
                            />
                            <iframe
                                v-else
                                :src="chat_file_preview_url"
                                frameborder="0"
                                class="default-layout-chfpr__iframe"
                            ></iframe>
                        </template>
                    </div>
                </div>
            </v-scale-transition>
        </v-main>
    </div>
</template>

<script>
import EventBus from "@/helpers/event-bus";
import NotificationsList from "@/components/NotificationsList";
import ChatDropdownList from "@/components/ChatDropdownList";
import ChatPopupWindow from "@/components/ChatPopupWindow";

export default {
    components: {
        NotificationsList,
        ChatDropdownList,
        ChatPopupWindow
    },

    data() {
        return {
            sidemenu: true,

            nav_items: [
                {
                    icon: "mdi-home",
                    title: "Dashboard",
                    to: {
                        name: "home"
                    },
                    required_permissions: [],
                    exact: true
                },

                {
                    icon: "mdi-file-document-edit",
                    title: "Wnioski",
                    to: {
                        name: "applications__list"
                    },
                    required_permissions: "applications.list"
                },
                {
                    icon: "mdi-chat",
                    title: "Konwersacje",
                    to: {
                        name: "chats__list"
                    },
                    required_permissions: "chats.list"
                },
                {
                    icon: "mdi-account-group",
                    title: "Użytkownicy",
                    to: {
                        name: "users__list"
                    },
                    required_permissions: "users.list"
                },
                {
                    icon: "mdi-format-list-checks",
                    title: "Zadania",
                    to: {
                        name: "tasks__list"
                    },
                    required_permissions: "tasks.list"
                }
            ],

            chat_file_preview: false,
            chat_file_preview_loading: true,
            chat_file_preview_type: null,
            chat_file_preview_url: null
        };
    },

    computed: {
        user() {
            return this.$store.state.auth.user || {};
        },
        filteredNavItems() {
            return this.nav_items.filter(item =>
                this.$store.getters["auth/userHasPermissions"](item.required_permissions)
            );
        },
        current_route_name() {
            return this.$route.name;
        },
        hide_chat_popups() {
            return ["chats__list", "chats__single"].indexOf(this.current_route_name) !== -1;
        }
    },

    methods: {
        handleChatWindowOpenRequest(chat_id) {
            // jeżeli jesteśmy na page /chats albo /chats/:id, to robimy redirect na tego typu page (nie otwieramy popup okienek)
            // jeżeli szerokość okna przeglądarki jest mniejsza niż 392px, to robimy redirect (nie ma miejsca na popup)
            if (this.hide_chat_popups || window.innerWidth < 392) {
                if (this.$route.name === "chats__single" && this.$route.params.id == chat_id)
                    return;
                return this.$router.push({
                    name: "chats__single",
                    params: {
                        id: chat_id
                    }
                });
            }

            // jeżeli dotarliśmy tutaj, no to wyświetlamy okienko czatu - ale ze sprawdzeniem ile mamy miejsca (trzeba 360px na czat + po 16px na spacing) - sprawdzenie delegujemy na koniec do osobnej funkcji - ona sprawdza ile się mieści i ucina nadmiarowe
            const CHAT = this.$store.getters["chats/getChat"](chat_id);
            if (CHAT) {
                const ix = this.$store.state.chats.opened_chats_popup_windows.findIndex(
                    it => it == chat_id
                );
                if (ix === -1) {
                    this.$store.state.chats.opened_chats_popup_windows.push(chat_id);
                    this.handleChatWindowCount();
                } else {
                    EventBus.$emit("ChatPopupWindow.focus_requested", chat_id);
                }
            }
        },

        handleChatWindowCount() {
            if (this.$store.state.chats.opened_chats_popup_windows.length === 0) return;

            let sum_width = 16;
            let window_count = 0;
            while (sum_width + 360 + 16 <= window.innerWidth && window_count < 3) {
                window_count += 1;
                sum_width += 360 + 16;
            }

            while (this.$store.state.chats.opened_chats_popup_windows.length > window_count) {
                this.$store.state.chats.opened_chats_popup_windows.shift();
            }
        },

        // FILE PREVIEW DIALOG
        handleFilePreviewRequest(data) {
            this.chat_file_preview = true;
            this.chat_file_preview_loading = true;
            this.chat_file_preview_type = data.file_type;

            window.addEventListener("keydown", this.filePreviewWindowClick);

            // preloading
            if (data.file_type == "image") {
                const IMG = new Image();
                IMG.onload = () => {
                    this.chat_file_preview_url = data.url;
                    this.chat_file_preview_loading = false;
                };
                IMG.onerror = () => {
                    this.chat_file_preview_url = null;
                    this.chat_file_preview_loading = false;
                };
                IMG.src = data.url;
            } else {
                this.chat_file_preview_url = data.url;
                this.chat_file_preview_loading = false;
            }
        },
        filePreviewWindowClick(e) {
            if (e.key == "Escape") {
                this.closeFilePreview();
            }
        },
        closeFilePreview() {
            this.chat_file_preview = false;

            window.removeEventListener("keydown", this.filePreviewWindowClick);
        }
    },

    mounted() {
        if (window.innerWidth < 600) {
            this.$nextTick(_ => {
                this.sidemenu = false;
            });
        }

        window.addEventListener("resize", this.handleChatWindowCount, { passive: true });
        EventBus.$on("open-chat-window", this.handleChatWindowOpenRequest);
        EventBus.$on("open-file-preview", this.handleFilePreviewRequest);
    },

    beforeDestroy() {
        window.removeEventListener("resize", this.handleChatWindowCount);
        EventBus.$off("open-chat-window", this.handleChatWindowOpenRequest);
        EventBus.$off("open-file-preview", this.handleFilePreviewRequest);
    }
};
</script>
