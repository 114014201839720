<template>
    <component
        :is="redirectMode == 'link' ? 'router-link' : 'button'"
        class="chat-conversation"
        :to="redirectMode == 'link' ? { name: 'chats__single', params: { id: chat._id } } : null"
        active-class="chat-conversation--active"
        @click="onButtonClick"
    >
        <div
            class="chat-conversation__wrapper rounded"
            :class="{
                'indigo lighten-5':
                    $route.name == 'chats__details' && $route.params.id === chat._id,
                'grey lighten-3': chat.unread_count > 0
            }"
        >
            <div class="chat-conversation__avatar">
                <v-badge
                    bordered
                    bottom
                    color="success"
                    dot
                    offset-x="10"
                    offset-y="10"
                    :value="debtor_loaded && debtor.activity_status == 'online'"
                >
                    <v-avatar
                        color="indigo"
                        class="white--text"
                    >
                        <v-progress-circular
                            indeterminate
                            size="16"
                            width="2"
                            v-if="!debtor_loaded"
                        />
                        <template v-else>
                            {{ debtor_initials }}
                        </template>
                    </v-avatar>
                </v-badge>
            </div>
            <div class="chat-conversation__details">
                <div
                    class="chat-conversation__name"
                    :class="{
                        'font-weight-medium': chat.unread_count > 0
                    }"
                >
                    <v-skeleton-loader
                        type="text"
                        v-if="!debtor_loaded"
                    />
                    <template v-else>
                        {{ debtor_loaded ? debtor.name : "" }}
                        <span class="text-caption">
                            ({{ chat.application.service_provider }}/{{ chat.application.number }})
                        </span>
                    </template>
                </div>
                <div
                    class="chat-conversation__subline text-caption"
                    :class="{
                        'font-weight-medium grey--text text--darken-2': chat.unread_count > 0
                    }"
                >
                    <div class="chat-conversation__lm">{{ latest_chat_message_text }}</div>
                    <div class="chat-conversation__sdot">·</div>
                    <div class="chat-conversation__date">
                        {{ la_date_formatted }}
                    </div>
                </div>
            </div>
        </div>
    </component>
</template>

<script>
import EventBus from "@/helpers/event-bus";

export default {
    props: {
        chat: {
            type: Object,
            required: true
        },
        redirectMode: {
            type: String,
            default: "link"
        }
    },

    data() {
        return {
            la_date_formatted: "",
            debtor_loaded: false,
            clock: null
        };
    },

    computed: {
        latest_chat_message_text() {
            if (!this.chat.last_message) return "<Brak wiadomości>";

            if (this.chat.last_message.message_type === "text_message") {
                return this.chat.last_message.message;
            }

            return "Przesłano plik(i)";
        },

        debtor() {
            return this.$store.getters["users/getUser"](this.chat.application.debtor);
        },

        debtor_initials() {
            if (!this.debtor) return "";

            const A = [];
            const SPL = this.debtor.name.split(" ");

            for (let i = 0; i < Math.min(2, SPL.length); i++) {
                A.push(SPL[i][0].toUpperCase());
            }

            return A.join("");
        }
    },

    watch: {
        "chat.last_message": {
            handler() {
                this.fetchDebtor();
            },
            immediate: true
        }
    },

    methods: {
        formatLaDate() {
            const diff = !this.chat.last_message ? 0 : Date.now() - this.chat.last_message.c_date;
            let str = "";

            if (diff < 1000 * 60 * 1) {
                str = "teraz";
            } else if (diff < 1000 * 60 * 60) {
                // minuty
                const M = Math.floor(diff / (1000 * 60));
                str = this.$store.getters["pluralize"]({ n: M, message: "minutes" });
            } else if (diff < 1000 * 60 * 60 * 23) {
                // godziny
                const M = Math.floor(diff / (1000 * 60 * 60));
                str = this.$store.getters["pluralize"]({ n: M, message: "hours" });
            } else if (diff < 1000 * 60 * 60 * 24 * 7) {
                // dni
                const M = Math.floor(diff / (1000 * 60 * 60 * 24));
                str = this.$store.getters["pluralize"]({ n: M, message: "days" });
            } else {
                // daleka data
                str = new Date(this.chat.la_date).toLocaleDateString();
            }

            this.la_date_formatted = str;
        },

        async fetchDebtor() {
            if (this.chat.application.debtor) {
                this.debtor_loaded = false;
                await this.$store.dispatch("users/fetchUser", this.chat.application.debtor);
                if (this.debtor) this.debtor_loaded = true;
            }
        },

        onButtonClick() {
            if (this.redirectMode !== "button") return;
            EventBus.$emit("open-chat-window", this.chat._id);
        }
    },

    mounted() {
        this.formatLaDate();
        this.clock = setInterval(() => {
            this.formatLaDate();
        }, 60 * 1000);
    },

    updated() {
        this.formatLaDate();
    },

    beforeDestroy() {
        if (this.clock != null) clearInterval(this.clock);
    }
};
</script>
