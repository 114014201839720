/*******************************************************************************
 *
 * PLIK ZAWIERAJĄCY MESSAGES MAJĄCE ZASTĘPOWAĆ ZWROTKI Z API PO polu error_code
 * ORAZ HELPERY DO ICH POBIERANIA
 *
 *******************************************************************************/
const MESSAGES = {
    auth: {
        e201: "Nieprawidłowy login lub hasło",
        e202: "Dokończ rejestrację, by móc się zalogować",
        e203: "Konto nie zostało aktywowane",
        e204: "Konto zostało zablokowane z powodu wielu nieudanych prób logowania",
        e205: "Konto zostało zawieszone przez Administrację serwisu",

        e301: "Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut",

        e401: "Nieprawidłowy lub nieważny token",

        e501: "Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut",

        e601: "Nieprawidłowy lub nieważny token",

        e901: "To konto nie może zostać aktywowane",
        e902: "Nieprawidłowy lub nieważny token",

        e1001: "To konto nie może być aktywowane",
        e1002: "Możliwość ponownego wysłania wiadomości będzie znowu dostępna za parę minut"
    },

    users: {
        e201: "Istnieje już w systemie konto powiązane z podanym adresem e-mail",

        e502: "Rozdzielczość przesłanego obrazu jest zbyt niska",
        e503: "Rozdzielczość przesłanego obrazu jest zbyt wysoka",

        e601: "Podano nieprawidłowe aktualne hasło",

        e1202: "Nie możesz usunąć swojego konta",
        e1203: "To konto nie może zostać usunięte",
        e1204: "Podano nieprawidłowe hasło"
    },

    applications: {
        e200: "Błąd walidacji danych",
        e201: "Każdy Dłużnik może posiadać tylko jeden aktywny Wniosek",
        e202: "Użytkownik przekazany jako Opiekun Klienta nie posiada odpowiednich uprawnień do tej roli",

        e400: "Błąd walidacji danych",
        e401: "Nie znaleziono szukanego Wniosku",
        e402: "Nie można modyfikować właściwości total_amount_gross oraz vat_rate jeżeli Wniosek ma status inny niż Szkic",
        e403: "Użytkownik przekazany jako Opiekun Klienta nie posiada odpowiednich uprawnień do tej roli",
        e404: "Użytkownik przekazany jako Prawnik nie posiada odpowiednich uprawnień do tej roli",

        e500: "Błąd walidacji danych",
        e501: "Nie znaleziono szukanego Wniosku",
        e502: "Na tym etapie (statusie) Wniosku nie można przeprowadzić częściowej aktualizacji obiektu debtor_personal_data",

        e700: "Błąd walidacji danych",
        e701: "Przesłana struktura obiektu Survey nie jest zgodna z wytycznymi",
        e702: "Nie znaleziono szukanego Wniosku",

        e800: "Błąd walidacji danych",
        e801: "Wniosek nie jest na wymaganym statusie, aby móc zatwierdzić płatności",
        e802: "Dodaj przynajmniej jedną Płatność, aby móc zatwierdzić płatności",
        e803: "Płatności dodane do Wniosku muszą w całości pokrywać total_amount_gross",

        e900: "Błąd walidacji danych",
        e901: "Wniosek nie jest na wymaganym statusie, aby móc zatwierdzić dane osobowe Dłużnika",
        e902: "Struktura danych Dłużnika nie przeszła walidacji pod kątem zgodności z modelem danych",

        e1000: "Błąd walidacji danych",
        e1001: "Wniosek nie jest na wymaganym statusie, aby móc zatwierdzić Ankietę",
        e1002: "Struktura danych obiektu Survey nie przeszła walidacji pod kątem zgodności z modelem danych",

        e9902: "Podano nieprawidłowe hasło"
    },

    payments: {
        e200: "Błąd walidacji danych",
        e201: "Data płatności powinna być przyszłą datą",
        e203: "Wniosek musi być na statusie CREATED, aby móc dodać nową Płatność",
        e204: "Możesz dodać do każdego Wniosku maksymalnie 48 Płatności",
        e205: "Podana kwota do zapłaty po zsumowaniu z innymi Płatnościami dla tego Wniosku przewyższa całkowitą kwotę do zapłaty zdefiniowaną w obiekcie Wniosku.",

        e400: "Błąd walidacji danych",
        e402: "Data płatności powinna być przyszłą datą",
        e404: "Kwota płatności nie może być już zmodyfikowana",
        e405: "Typ płatności nie może być już zmodyfikowany",
        e407: "Podana kwota do zapłaty po zsumowaniu z innymi Płatnościami dla tego Wniosku przewyższa całkowitą kwotę do zapłaty zdefiniowaną w obiekcie Wniosku.",

        e503: "Nie można usuwać Płatności na tym etapie życia Wniosku"
    },

    debts: {
        e602: "Nie można usunąć Wierzytelności, która zawiera jakikolwiek Dokument. Usuń najpierw jej Dokumenty."
    },

    documents: {
        e403: "Ten Dokument został zablokowany przez Prawnika i nie może być edytowany",
        e502: "Ten typ Dokumentu nie może zostać usunięty",
        e503: "Ten Dokument został zablokowany przez Prawnika i nie może być już usunięty"
    },

    files: {
        e206: "Dokument, do którego próbujesz wgrać pliki jest zablokowany, przez co nie można dodać do niego już więcej plików",
        e803: "Dokument, do którego próbujesz wgrać pliki jest zablokowany, przez co nie można usunąć żadnego z plików w nim zawartych"
    }
};

function getNestedObjProperty(obj, property) {
    let work_obj = obj;
    const splitted_property = property.split(".");

    for (let i = 0; i < splitted_property.length; i++) {
        if (typeof work_obj != "object" || work_obj[splitted_property[i]] == undefined) {
            return null;
        }

        work_obj = work_obj[splitted_property[i]];
    }

    return work_obj;
}

export function messageExists(path) {
    if (!path || path == "") return false;
    return getNestedObjProperty(MESSAGES, path) !== null;
}

export function getMessage(path) {
    if (!path || path == "") return "";
    const t = getNestedObjProperty(MESSAGES, path);
    return t === null ? path : t;
}
