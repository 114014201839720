var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"notification-tile",class:{
        'notification-tile--unread': _vm.notification.is_read === false
    },attrs:{"to":_vm.to},nativeOn:{"click":function($event){return _vm.markAsRead.apply(null, arguments)}}},[_c('div',{staticClass:"notification-tile__image"},[_c('v-avatar',{attrs:{"size":"48","color":"primary"}},[(_vm.debtor_image_notification)?[(_vm.debtor.profile_image && _vm.debtor.profile_image.s3_file_url_128px != '')?_c('v-img',{attrs:{"src":_vm.debtor.profile_image.s3_file_url_128px}}):_c('div',{staticClass:"text-center white--text font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$store.getters["auth/userInitials"](_vm.debtor))+" ")])]:_c('v-img',{attrs:{"src":require("@/assets/img/pravna-avatar.png")}})],2)],1),_c('div',{staticClass:"notification-tile__content text-body-2"},[(_vm.notification.code == _vm.$store.state.NOTIFICATION_CODES.APP_STATUS_CHANGE)?[_c('span',{staticClass:"notification-tile__bold"},[_vm._v(_vm._s(_vm.debtor.name))]),_vm._v(" zmienił status swojego wniosku "),_c('span',{staticClass:"notification-tile__bold"},[_vm._v(_vm._s(_vm.notification.payload.application_name))]),_vm._v(" na "),_c('span',{staticClass:"notification-tile__bold"},[_vm._v(" "+_vm._s(_vm.$store.state.applications.APPLICATION_STATUSES_LABELS[ _vm.notification.payload.current_status ])+" ")])]:(
                _vm.notification.code == _vm.$store.state.NOTIFICATION_CODES.APP_CUSTOM_DOCUMENT_CREATED
            )?[_c('span',{staticClass:"notification-tile__bold"},[_vm._v(_vm._s(_vm.debtor.name))]),_vm._v(" dodał nowy Dokument ("),_c('span',{staticClass:"notification-tile__bold"},[_vm._v(" "+_vm._s(_vm.notification.payload.document_name)+" ")]),_vm._v(") do swojego wniosku "),_c('span',{staticClass:"notification-tile__bold"},[_vm._v(_vm._s(_vm.notification.payload.application_name))])]:(
                _vm.notification.code == _vm.$store.state.NOTIFICATION_CODES.APP_DOCUMENT_FILE_UPLOADED
            )?[_c('span',{staticClass:"notification-tile__bold"},[_vm._v(_vm._s(_vm.debtor.name))]),_vm._v(" dodał "),(_vm.notification.combined_notifications_number > 0)?_c('span',{staticClass:"notification-tile__bold"},[_vm._v(" "+_vm._s(_vm.$store.getters["pluralize"]({ n: _vm.notification.combined_notifications_number + 1, values: ["", "1 plik", "{n} pliki", "{n} plików"] }))+" ")]):[_vm._v(" plik "),_c('span',{staticClass:"notification-tile__bold",staticStyle:{"word-break":"break-word !important"}},[_vm._v(" "+_vm._s(_vm.notification.payload.file_name)+" ")])],_vm._v(" do Dokumentu "),_c('span',{staticClass:"notification-tile__bold"},[_vm._v(" "+_vm._s(_vm.notification.payload.document_name || "-")+" ")])]:(
                _vm.notification.code == _vm.$store.state.NOTIFICATION_CODES.APP_DOCUMENTS_EXPORTED
            )?[_vm._v(" Eksport dokumentów dla Wniosku "),_c('span',{staticClass:"notification-tile__bold"},[_vm._v(_vm._s(_vm.notification.payload.application_name))]),_vm._v(" został ukończony, pliki są gotowe do pobrania. ")]:_vm._e(),_c('div',{staticClass:"mt-1 text-right text-caption grey--text"},[_vm._v(" "+_vm._s(_vm.formatDateWithTime(new Date(_vm.notification.c_date)))+" ")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }