<template>
    <v-dialog
        :value="value"
        @input="emitInput"
        max-width="450"
        scrollable
    >
        <v-card>
            <v-card-title> Rozpocznij konwersację z dłużnikiem </v-card-title>
            <v-divider></v-divider>
            <v-card-text
                class="pt-5"
                style="max-height: 70vh"
            >
                <v-autocomplete
                    cache-items
                    hint="Wpisz numer wniosku lub imię i nazwisko lub e-mail dłużnika, aby wyszukać"
                    :items="search_items"
                    item-value="_id"
                    label="Wniosek (dłużnik)"
                    :loading="searching"
                    no-data-text="Brak dopasowania dla podanej frazy"
                    no-filter
                    outlined
                    persistent-hint
                    v-model="si_selected_item"
                    :search-input.sync="search_string"
                >
                    <template v-slot:selection="{ item }">
                        <ApplicationsKanbanCard
                            :application="item"
                            style="width: 100%; pointer-events: none"
                        />
                    </template>
                    <template v-slot:item="{ item }">
                        <ApplicationsKanbanCard
                            :application="item"
                            style="width: 100%; pointer-events: none"
                            class="my-1"
                        />
                    </template>
                </v-autocomplete>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="grey lighten-1"
                    text
                    :disabled="loading"
                    @click="emitInput(false)"
                >
                    Anuluj
                </v-btn>
                <v-btn
                    color="primary"
                    text
                    :loading="loading"
                    @click="startChat"
                >
                    Otwórz czat
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import EventBus from "@/helpers/event-bus";
import ApplicationsKanbanCard from "@/components/ApplicationsKanbanCard";

export default {
    components: {
        ApplicationsKanbanCard
    },

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            searching: false,
            search_debouncer: null,
            search_items: [],
            search_string: "",
            si_selected_item: "",

            loading: false
        };
    },

    watch: {
        search_string(nv, ov) {
            if (this.search_debouncer != null) clearTimeout(this.search_debouncer);
            this.search_debouncer = setTimeout(() => {
                const old_value = ov;
                const new_value = nv;

                if (new_value != "" && new_value != null && new_value != old_value) {
                    this.doSearch(new_value);
                }

                this.search_debouncer = null;
            }, 500);
        }
    },

    methods: {
        emitInput(v) {
            this.$emit("input", v);
        },

        async doSearch(term) {
            this.searching = true;

            try {
                const r = await this.$axios.$get(
                    `/applications/?filter_search=${encodeURIComponent(term)}`
                );

                this.search_items = r.applications;
            } catch (err) {
                console.error(err);
            }

            this.searching = false;
        },

        async startChat() {
            if (this.loading) return;

            if (!this.si_selected_item || this.si_selected_item === "") {
                return this.$message({
                    type: "error",
                    msg: "Wybierz wniosek (Dłużnika), z którym chcesz rozpocząć czat."
                });
            }
            this.loading = true;

            try {
                const r = await this.$axios.$get(`/applications/` + this.si_selected_item);
                // console.log(r);

                if (r.application) {
                    const CHAT_OBJ = this.$store.getters[
                        "chats/getChatInsertObjectFromApplicationObject"
                    ](r.application);

                    this.$store.commit("chats/insertChat", CHAT_OBJ);
                    EventBus.$emit("open-chat-window", CHAT_OBJ._id);
                    this.$emit("input", false);
                } else {
                    this.$message({
                        type: "error",
                        msg: "Nie udało się zainicjalizować czatu z wybranym użytkownikiem."
                    });
                }
            } catch (err) {
                console.error(err);
                this.$message({
                    type: "error",
                    msg: "Nie udało się zainicjalizować czatu z wybranym użytkownikiem (XoA1)."
                });
            }

            this.loading = false;
        }
    }
};
</script>
