<template>
    <router-link
        :to="{
            name: 'applications__edit',
            params: {
                id: application._id
            }
        }"
        class="appkan-card"
    >
        <v-card
            class="appkan-card__body pa-2"
            flat
        >
            <v-chip
                color="error"
                x-small
                class="mt-n1"
                v-if="application.notifications_count"
            >
                <v-icon
                    x-small
                    left
                    >mdi-bell-alert</v-icon
                >
                {{
                    $store.getters["pluralize"]({
                        n: application.notifications_count,
                        values: ["", "1 powiadomienie", "{n} powiadomienia", "{n} powiadomień"]
                    })
                }}
            </v-chip>
            <div class="font-weight-medium text-body-2">
                {{ application.service_provider }}/{{ application.number }}
            </div>
            <div
                class="text-caption grey--text"
                style="margin-top: -2px"
            >
                <v-skeleton-loader
                    type="text"
                    v-if="!debtor_loaded"
                    max-width="80%"
                ></v-skeleton-loader>
                <template v-else>
                    {{ debtor.name }}
                </template>
            </div>
            <div class="mt-1">
                <v-skeleton-loader
                    type="text"
                    v-if="!laywer_loaded"
                ></v-skeleton-loader>
                <div
                    class="d-flex align-center"
                    v-else
                >
                    <v-avatar
                        size="16"
                        color="primary"
                        class="mr-1"
                    >
                        <v-img
                            v-if="
                                lawyer.profile_image && lawyer.profile_image.s3_file_url_128px != ''
                            "
                            :src="lawyer.profile_image.s3_file_url_128px"
                        />
                        <div
                            class="text-center white--text font-weight-regular"
                            style="font-size: 8px; line-height: 8px"
                            v-else
                        >
                            {{ $store.getters["auth/userInitials"](lawyer) }}
                        </div>
                    </v-avatar>
                    <div class="text-caption grey--text">
                        {{ lawyer.name }}
                    </div>
                </div>
            </div>
        </v-card>
    </router-link>
</template>

<script>
export default {
    props: {
        application: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            laywer_loaded: false,
            debtor_loaded: false
        };
    },

    computed: {
        lawyer() {
            return this.$store.getters["users/getUser"](this.application.lawyer);
        },
        debtor() {
            return this.$store.getters["users/getUser"](this.application.debtor);
        }
    },

    methods: {
        async handleUsers() {
            this.laywer_loaded = false;
            this.debtor_loaded = false;

            const A = [];
            if (!this.$store.getters["users/userIsFetched"](this.application.lawyer)) {
                A.push(this.$store.dispatch("users/fetchUser", this.application.lawyer));
            }
            if (!this.$store.getters["users/userIsFetched"](this.application.debtor)) {
                A.push(this.$store.dispatch("users/fetchUser", this.application.debtor));
            }

            if (A.length > 0) await Promise.all(A);
            this.laywer_loaded = true;
            this.debtor_loaded = true;
        }
    },

    mounted() {
        if (!this.$store.state.initial_layout_set) return;
        this.handleUsers();
    }
};
</script>
