<template>
    <div class="chat-message-separator text-center text-caption grey--text my-3">
        {{ formatDate(item.timestamp) }}
    </div>
</template>
<script>
import formatDateWithTime from "@/helpers/format-date-with-time";

export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },

    methods: {
        formatDate(timestamp) {
            return formatDateWithTime(new Date(timestamp));
        }
    }
};
</script>
