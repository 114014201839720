import { render, staticRenderFns } from "./ChatMessageSeparator.vue?vue&type=template&id=adc78b54"
import script from "./ChatMessageSeparator.vue?vue&type=script&lang=js"
export * from "./ChatMessageSeparator.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports