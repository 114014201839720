<template>
    <emoji-picker
        @emoji="e => $emit('emoji', e)"
        ref="emp"
    >
        <v-btn
            slot="emoji-invoker"
            slot-scope="{ events: { click: clickEvent } }"
            text
            @click.stop="
                e => {
                    clickEvent(e);
                }
            "
            class="cep__invoker"
            ref="invoker"
        >
            <v-icon size="24">mdi-emoticon-outline</v-icon>
        </v-btn>
        <div
            slot="emoji-picker"
            slot-scope="{ insert, display }"
        >
            <div
                class="cep-window"
                :data-visible="display.visible"
                ref="popper"
            >
                <div class="cep-window__inner">
                    <div
                        v-for="(emojiGroup, category) in emojis"
                        :key="category"
                        class="cep-window__category"
                    >
                        <h5 class="cep-window__catname text-body-2 text-uppercase grey--text">
                            {{ dictionary.categories[category] }}
                        </h5>
                        <div class="cep-window__emgrid">
                            <span
                                v-for="(emoji, emojiName) in emojiGroup"
                                :key="emojiName"
                                @click="insert(emoji)"
                                :title="emojiName"
                                class="cep-window__emoji"
                                >{{ emoji }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </emoji-picker>
</template>

<script>
import { createPopper } from "@popperjs/core";
import EmojiPicker from "vue-emoji-picker";
import emojis from "vue-emoji-picker/src/emojis";
delete emojis["Frequently used"];

export default {
    components: {
        EmojiPicker
    },

    data() {
        return {
            dictionary: {
                categories: {
                    People: "Ludzie",
                    Nature: "Natura i zwierzęta",
                    Objects: "Przedmioty i jedzenie",
                    Places: "Miejsca i transport",
                    Symbols: "Symbole"
                }
            },

            popper_state: false,
            popper_instance: null,

            emojis
        };
    },

    watch: {
        popper_state() {
            if (this.popper_state === false && this.popper_instance != null) {
                this.destroyPopper();
            } else if (this.popper_state === true && this.popper_instance === null) {
                this.$nextTick(this.mountPopper);
            }
        }
    },

    methods: {
        mountPopper() {
            this.popper_instance = createPopper(this.$refs.invoker, this.$refs.popper, {
                placement: "top-start",
                strategy: "fixed",
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 8]
                        }
                    },
                    {
                        name: "flip",
                        options: {
                            fallbackPlacements: ["bottom-start", "right-start", "left-start"]
                        }
                    },
                    {
                        name: "preventOverflow",
                        options: {
                            mainAxis: true,
                            padding: 16
                        }
                    }
                ]
            });
        },
        destroyPopper() {
            this.popper_instance.destroy();
            this.popper_instance = null;
        }
    },

    mounted() {
        this.$watch(
            "$refs.emp.display",
            nv => {
                this.popper_state = nv.visible;
            },
            {
                deep: true
            }
        );
    }
};
</script>
