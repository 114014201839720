<template>
    <component
        class="user-tile d-flex align-center text-decoration-none"
        :is="tag"
        :to="to"
    >
        <template v-if="loaded && localUser">
            <v-avatar
                class="mr-2"
                :size="computedAvatarSize"
                color="primary"
            >
                <v-img
                    v-if="
                        localUser.profile_image && localUser.profile_image.s3_file_url_128px != ''
                    "
                    :src="localUser.profile_image.s3_file_url_128px"
                />
                <div
                    class="text-center white--text font-weight-medium"
                    v-else
                >
                    {{ $store.getters["auth/userInitials"](localUser) }}
                </div>
            </v-avatar>
            <div class="text-body-2">
                <div
                    class="font-weight-medium"
                    style="color: #434343"
                >
                    {{ localUser.name }}
                    <span
                        class="font-weight-regular"
                        v-if="
                            !hideNameDetails &&
                            $store.state.auth.user &&
                            $store.state.auth.user._id == localUser._id
                        "
                    >
                        (Ty)
                    </span>
                </div>
                <div
                    class="grey--text text-caption"
                    style="margin-top: -4px"
                >
                    <template v-if="detailsKey == 'role'">
                        {{ $store.state.USER_ROLE_LABELS[localUser.role] }}
                    </template>
                    <template v-else>
                        {{ localUser[detailsKey] }}
                    </template>
                </div>
            </div>
        </template>
        <template v-else>
            <v-skeleton-loader
                class="mr-2"
                :width="computedAvatarSize"
                :height="computedAvatarSize"
                type="avatar"
            />
            <div>
                <v-skeleton-loader
                    width="90"
                    height="12"
                    type="text"
                />
                <v-skeleton-loader
                    width="60"
                    height="8"
                    type="text"
                    class="mt-1"
                />
            </div>
        </template>
    </component>
</template>

<script>
export default {
    props: {
        user: {
            type: [Object, String]
        },
        tag: {
            type: String,
            default: "div"
        },
        to: {
            type: [String, Object],
            default: ""
        },
        detailsKey: {
            type: String,
            default: "role"
        },
        avatarSize: {
            type: [Number, String],
            default: 42
        },
        hideNameDetails: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loaded: false
        };
    },

    computed: {
        computedAvatarSize() {
            return parseInt(this.avatarSize);
        },
        localUser() {
            if (typeof this.user == "object") {
                return this.user;
            }
            return this.$store.getters["users/getUser"](this.user);
        }
    },

    watch: {
        user() {
            this.handleUser();
        }
    },

    methods: {
        async handleUser() {
            this.loaded = false;

            if (typeof this.user == "object") {
                this.loaded = true;
            } else if (typeof this.user == "string" && this.user != "") {
                await this.$store.dispatch("users/fetchUser", this.user);
                this.loaded = true;
            }
        }
    },

    mounted() {
        this.handleUser();
    }
};
</script>
