<template>
    <div class="chat-popup-window">
        <div
            class="chat-popup-window__header"
            :class="{
                primary: mark_as_unread
            }"
        >
            <div class="chat-popup-window__huser">
                <v-badge
                    bordered
                    bottom
                    color="success"
                    dot
                    offset-x="16"
                    offset-y="8"
                    :value="debtor_loaded && debtor.activity_status == 'online'"
                >
                    <v-avatar
                        size="40"
                        color="secondary"
                        class="white--text text-body-2 mr-2"
                    >
                        {{ debtor_initials }}
                    </v-avatar>
                </v-badge>
                <div class="chat-popup-window__hudet">
                    <template v-if="header_loading">
                        <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                    </template>
                    <template v-else>
                        <div
                            class="text-body-1"
                            :class="{
                                'white--text font-weight-medium': mark_as_unread
                            }"
                        >
                            {{ debtor.name }}
                        </div>
                        <div
                            class="text-caption mt-n1 grey--text"
                            :class="{
                                'text--lighten-2': mark_as_unread
                            }"
                        >
                            {{ chat.application.service_provider }}/{{ chat.application.number }}
                        </div>
                    </template>
                </div>
            </div>
            <div class="chat-popup-window__hactions">
                <v-btn
                    icon
                    small
                    :color="mark_as_unread ? 'white' : 'secondary'"
                    @click="focused = false"
                    v-if="focused"
                >
                    <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-btn
                    icon
                    small
                    :color="mark_as_unread ? 'white' : 'secondary'"
                    v-else
                    @click="maximizeWindow"
                >
                    <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                    icon
                    small
                    :color="mark_as_unread ? 'white' : 'secondary'"
                    @click="closeChat"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
        </div>
        <div
            class="chat-popup-window__body"
            v-if="focused"
        >
            <ChatFeed :chat-id="chatId" />
            <div class="chat-popup-window__msginp">
                <ChatMessageInput
                    :chat-id="chatId"
                    focusable
                />
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/helpers/event-bus";
import ChatMessageInput from "@/components/ChatMessageInput.vue";
import ChatFeed from "@/components/ChatFeed.vue";

export default {
    components: {
        ChatMessageInput,
        ChatFeed
    },

    props: {
        chatId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            debtor_loaded: false,
            focused: true
        };
    },

    computed: {
        chat() {
            return this.$store.getters["chats/getChat"](this.chatId);
        },
        header_loading() {
            return !this.chat || !this.debtor_loaded;
        },

        mark_as_unread() {
            return this.chat && this.chat.unread_count > 0;
        },

        debtor() {
            return this.$store.getters["users/getUser"](this.chat.application.debtor);
        },

        debtor_initials() {
            if (!this.debtor_loaded) return "";

            const A = [];
            const SPL = this.debtor.name.split(" ");

            for (let i = 0; i < Math.min(2, SPL.length); i++) {
                A.push(SPL[i][0].toUpperCase());
            }

            return A.join("");
        }
    },

    watch: {
        chat: {
            handler() {
                this.fetchDebtor();
            },
            immediate: true
        }
    },

    methods: {
        async fetchDebtor() {
            if (!this.chat || !this.chat.application.debtor) return;

            this.debtor_loaded = false;
            await this.$store.dispatch("users/fetchUser", this.chat.application.debtor);
            if (this.debtor) this.debtor_loaded = true;
        },

        closeChat() {
            const ix = this.$store.state.chats.opened_chats_popup_windows.findIndex(
                it => it == this.chatId
            );
            if (ix !== -1) {
                this.$store.state.chats.opened_chats_popup_windows.splice(ix, 1);
            }
        },

        onFocusRequested() {
            if (!this.focused) this.focused = true;
        },

        maximizeWindow() {
            this.focused = true;
            this.$store.dispatch("chats/markChatMessagesAsRead", {
                chat: this.chatId
            });
        }
    },

    mounted() {
        EventBus.$on("ChatPopupWindow.focus_requested", this.onFocusRequested);
    },

    beforeDestroy() {
        EventBus.$off("ChatPopupWindow.focus_requested", this.onFocusRequested);
    }
};
</script>
