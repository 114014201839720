<template>
    <div
        class="chat-feed"
        ref="feed_scrollarea"
        v-if="chat"
        @dragover="onDragOver"
        @drop="onDrop"
    >
        <div class="chat-feed__wrapper">
            <component
                v-for="(entry, ix) in chat_feed"
                :key="entry.item_data._id"
                :is="entry.item_type == 'message' ? 'ChatMessage' : 'ChatMessageSeparator'"
                :item="entry.item_data"
                :current-user="$store.state.auth.user._id"
                :recipient="chat.application.debtor"
                :is-last-in-creator-group="entry.last_in_creator_group"
                :is-first-in-creator-group="entry.first_in_creator_group"
                @open-file-preview="openFilePreview"
            />
            <div
                style="height: 1px; margin-bottom: -1px"
                v-intersect="onIntersect"
            ></div>
            <div
                class="py-2 text-center"
                v-if="chat.chm_fetching"
            >
                <v-progress-circular
                    color="primary"
                    size="16"
                    width="2"
                    indeterminate
                />
            </div>
        </div>

        <div
            class="chat-feed__fdovrl"
            :class="{
                'chat-feed__fdovrl--fixed': fixedFileDropOverlay
            }"
            v-if="show_dragover_plane"
        >
            <div>
                <v-icon
                    size="36"
                    color="#b0b0b0"
                    >mdi-upload</v-icon
                >
                <div class="mt-1 text-body-2">Upuść plik(i)</div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/helpers/event-bus";
import ChatMessage from "./ChatMessage.vue";
import ChatMessageSeparator from "./ChatMessageSeparator.vue";

export default {
    components: {
        ChatMessage,
        ChatMessageSeparator
    },

    props: {
        chatId: {
            type: String,
            required: true
        },
        fixedFileDropOverlay: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            show_dragover_plane: false,
            dragend_debouncer: null
        };
    },

    computed: {
        chat() {
            return this.$store.getters["chats/getChat"](this.chatId);
        },

        chat_feed() {
            function getSeparatorId() {
                return "sep_" + Date.now() + "_" + Math.floor(Math.random() * 100000).toString(16);
            }

            const NEWEST = {};

            const MESSAGES = this.$store.getters["chats/getChatMessages"](this.chatId);

            /*
                Konstruowanie sensownego feedu z wiadomościami - czylu wiadomości przedzielone separatorami. Sepraratory dodawane są według zasad rozpisanych poniżej w kodzie
                Wiadomości są ułożone od najnowszych na dzień dobry
            */

            const FEED = [];

            let current_creator_group_id = 0;
            let current_creator_group_count = 0;
            let current_date_group_id = 0;
            let current_date_group_count = 0;
            let current_date_group_avg = 0;

            for (let i = 0; i < MESSAGES.length; i++) {
                const ADD = {};

                // sprawdzamy, czy wiadomość jest pierwsza od danego twórcy na statusie read
                if (MESSAGES[i].status == "read" && NEWEST[MESSAGES[i].creator] === undefined) {
                    MESSAGES[i].newest_read_of_its_creator = true;
                    NEWEST[MESSAGES[i].creator] = MESSAGES[i]._id;
                } else {
                    MESSAGES[i].newest_read_of_its_creator = false;
                }

                // wszystkie operacje grupowe robimy od drugiego elementu, żeby móc spojrzeć 'do tyłu' dla referencji
                if (i > 0) {
                    let date_group_changed = false;

                    // 1. zmiana grupy datowanej
                    // 1.1 sprawdzamy, czy pomiędzy sąsiednimi wiadomościami nie zmieniła się data
                    const PREVIOUS_DATE = new Date(MESSAGES[i - 1].c_date);
                    const CURRENT_DATE = new Date(MESSAGES[i].c_date);
                    if (
                        PREVIOUS_DATE.getFullYear() != CURRENT_DATE.getFullYear() ||
                        PREVIOUS_DATE.getMonth() != CURRENT_DATE.getMonth() ||
                        PREVIOUS_DATE.getDate() != CURRENT_DATE.getDate()
                    ) {
                        date_group_changed = true;
                    }
                    // 1.2 jeżeli tą wiadomość od poprzedniej dzieli przynajmniej 30 minut, to dodajemy separator
                    else if (MESSAGES[i - 1].c_date - MESSAGES[i].c_date >= 30 * 60 * 1000) {
                        date_group_changed = true;
                    }

                    // 1.3. liczymy średnią czasu pomiędzy sąsiednimi wiadomościami w aktualnej grupie na bieżąco i porównujemy z nowym odstępem - to możemy zrobić tylko od trzeciej wiadomości w danej grupie
                    if (current_date_group_count >= 1) {
                        // jeżeli mamy przynajmniej drugi element w grupie, to możemy liczyć już średnią, ale nie mamy jeszcze z czym jej porównać
                        let current_diff = MESSAGES[i - 1].c_date - MESSAGES[i].c_date;

                        if (current_date_group_count === 1) {
                            // jeżeli to jest dopiero drugi, to tylko zapisujemy ten diff, jako obecną średnią
                            current_date_group_avg = current_diff;
                        } else {
                            // porównujemy aktualną średnią (już mamy tam coś zapisane) z aktualnym diffem - jeżeli średnia jest przynajmniej 4x mniejsza i diff to przynajmniej 10 minut, to robimy separator
                            if (
                                !date_group_changed &&
                                current_diff / 4 >= current_date_group_avg &&
                                current_diff >= 10 * 60 * 1000
                            ) {
                                date_group_changed = true;
                            }

                            // tak czy inaczej aktualizujemy średnią
                            current_date_group_avg =
                                (current_date_group_avg * (current_date_group_count - 1) +
                                    current_diff) /
                                current_date_group_count;
                        }
                    }

                    // 2. jeżeli zmienił się creator lub była zmiana grupy dat to robimy nową grupę, a ponieważ patrzymy od tyłu na feed, to oznaczamy też tą wiadomość jako ostatnią w grupie, a poprzednią jako pierwszą w tamtej poprzedniej grupie
                    if (date_group_changed || MESSAGES[i].creator != MESSAGES[i - 1].creator) {
                        current_creator_group_count = 0;
                        current_creator_group_id += 1;

                        ADD.last_in_creator_group = true;
                        FEED[FEED.length - 1].first_in_creator_group = true;
                    }

                    // 3. jeżeli zmieniła się grupa daty, to teraz dopiero push do FEED (inaczej nie ma gwaracji, że poprzedni element tam wrzucony jest wiadomością)
                    if (date_group_changed) {
                        current_date_group_count = 0;
                        current_date_group_id += 1;
                        current_date_group_avg = 0;

                        ADD.last_in_date_group = true;
                        FEED[FEED.length - 1].first_in_date_group = true;

                        FEED.push({
                            item_type: "separator",
                            item_data: {
                                _id: getSeparatorId(),
                                timestamp: MESSAGES[i - 1].c_date
                            }
                        });
                    }
                }

                // dodatkowe operacje
                if (i === 0) {
                    // jeżeli element jest pierwszy, to zawsze będzie ostatni w grupach
                    ADD.last_in_creator_group = true;
                    ADD.last_in_date_group = true;
                } else if (i == MESSAGES.length - 1) {
                    // jeżeli element jest ostatni, to zawsze jest pierwszy w grupach
                    ADD.first_in_creator_group = true;
                    ADD.first_in_date_group = true;
                }

                ADD.creator_group = `crg_${current_creator_group_id}`;
                ADD.date_group = `dtg_${current_date_group_id}`;

                if (ADD.last_in_creator_group === undefined) {
                    ADD.last_in_creator_group = false;
                }
                if (ADD.first_in_creator_group === undefined) {
                    ADD.first_in_creator_group = false;
                }

                if (ADD.last_in_date_group === undefined) {
                    ADD.last_in_date_group = false;
                }
                if (ADD.first_in_date_group === undefined) {
                    ADD.first_in_date_group = false;
                }

                current_creator_group_count += 1;
                current_date_group_count += 1;

                // console.log(current_creator_group_count, current_date_group_count);

                FEED.push({
                    item_type: "message",
                    item_data: MESSAGES[i],
                    ...ADD
                });

                // ostatni separator (nad pierwszą wiadomością)
                if (i == MESSAGES.length - 1) {
                    FEED.push({
                        item_type: "separator",
                        item_data: {
                            _id: getSeparatorId(),
                            timestamp: MESSAGES[i].c_date
                        }
                    });
                }
            }

            // console.log(FEED);
            return FEED;
        }
    },

    watch: {
        chat_feed(nv, ov) {
            this.handleChatWindowScroll({
                ov,
                nv
            });
        }
    },

    methods: {
        onIntersect(entries, observer, isIntersecting) {
            if (isIntersecting && !this.chat.messages_loading) {
                this.$store.dispatch("chats/fetchChatMessages", this.chatId);
            }
        },

        handleChatWindowScroll(opts = {}) {
            // Ta metoda ma za zadanie zapanować nad scrollem chatarea:
            // 1. Jeżeli okno było przescrollowane na sam dół (lub tego bliskie), to utrzymujemy tę pozycję
            // 2. Jeżeli nie, to utrzymujemy obecną pozycję
            const EL = this.$refs.feed_scrollarea;
            if (!EL) return;

            const BOTTOM_OFFSET = EL.scrollHeight - EL.scrollTop - EL.offsetHeight;
            const BEFORE_SCROLL_HEIGHT = EL.scrollHeight;
            const BEFORE_SCROLL_TOP = EL.scrollTop;
            // console.log(BEFORE_SCROLL_HEIGHT, BEFORE_SCROLL_TOP);

            this.$nextTick(() => {
                if (
                    (BOTTOM_OFFSET <= 36 || opts.force_to_bottom === true) &&
                    this.chat_feed.length > 0
                ) {
                    EL.scrollTo({
                        left: 0,
                        top: EL.scrollHeight - EL.offsetHeight
                    });
                } else {
                    // jeżeli mamy przekazane zmienione wartości feeda, to sprawdzamy, czy ostatnie elementy będące wiadomościami w tych tablicach mają takie samo ID - jeżeli nie, to znaczy, że nastąpiło doczytanie przez infinite scroll i musimy utrzymać scroll position w nieco inny sposób
                    let LAST_OV_MESSAGE = null;
                    let LAST_NV_MESSAGE = null;
                    if (opts.ov && opts.nv) {
                        for (let i = opts.ov.length - 1; i >= 0; i--) {
                            if (opts.ov[i].item_type == "message") {
                                LAST_OV_MESSAGE = opts.ov[i];
                                break;
                            }
                        }

                        for (let i = opts.nv.length - 1; i >= 0; i--) {
                            if (opts.nv[i].item_type == "message") {
                                LAST_NV_MESSAGE = opts.nv[i];
                                break;
                            }
                        }
                    }

                    if (
                        LAST_NV_MESSAGE != null &&
                        LAST_OV_MESSAGE != null &&
                        LAST_NV_MESSAGE.item_data._id != LAST_OV_MESSAGE.item_data._id
                    ) {
                        EL.scrollTo({
                            left: 0,
                            top: EL.scrollHeight - BEFORE_SCROLL_HEIGHT + BEFORE_SCROLL_TOP
                        });
                    } else {
                        EL.scrollTo({
                            left: 0,
                            top: BEFORE_SCROLL_TOP
                        });
                    }
                }

                // console.log(EL.scrollHeight, EL.scrollTop);
            });
        },

        // FILE DROP
        onDragOver(e) {
            e.preventDefault();
            this.show_dragover_plane = true;

            if (this.dragend_debouncer != null) clearTimeout(this.dragend_debouncer);
            this.dragend_debouncer = setTimeout(() => {
                this.dragend_debouncer = null;
                this.show_dragover_plane = false;
            }, 500);
        },
        onDrop(e) {
            e.preventDefault();
            this.show_dragover_plane = false;

            if (!e.dataTransfer || !e.dataTransfer.files || e.dataTransfer.files.length == 0)
                return;

            EventBus.$emit("chat-window-file-dropped", {
                chat: this.chatId,
                files: e.dataTransfer.files
            });
        },

        // FILE PREVIEW
        openFilePreview() {
            console.log("OPEN FILE PREVIEW");
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.handleChatWindowScroll({
                force_to_bottom: true
            });
        });
    }
};
</script>
