<template>
    <router-link
        :to="to"
        class="notification-tile"
        :class="{
            'notification-tile--unread': notification.is_read === false
        }"
        @click.native="markAsRead"
    >
        <div class="notification-tile__image">
            <v-avatar
                size="48"
                color="primary"
            >
                <template v-if="debtor_image_notification">
                    <v-img
                        v-if="debtor.profile_image && debtor.profile_image.s3_file_url_128px != ''"
                        :src="debtor.profile_image.s3_file_url_128px"
                    />
                    <div
                        class="text-center white--text font-weight-regular"
                        v-else
                    >
                        {{ $store.getters["auth/userInitials"](debtor) }}
                    </div>
                </template>
                <v-img
                    v-else
                    src="@/assets/img/pravna-avatar.png"
                />
            </v-avatar>
        </div>
        <div class="notification-tile__content text-body-2">
            <template v-if="notification.code == $store.state.NOTIFICATION_CODES.APP_STATUS_CHANGE">
                <span class="notification-tile__bold">{{ debtor.name }}</span> zmienił status
                swojego wniosku
                <span class="notification-tile__bold">{{
                    notification.payload.application_name
                }}</span>
                na
                <span class="notification-tile__bold">
                    {{
                        $store.state.applications.APPLICATION_STATUSES_LABELS[
                            notification.payload.current_status
                        ]
                    }}
                </span>
            </template>
            <template
                v-else-if="
                    notification.code == $store.state.NOTIFICATION_CODES.APP_CUSTOM_DOCUMENT_CREATED
                "
            >
                <span class="notification-tile__bold">{{ debtor.name }}</span> dodał nowy Dokument
                (<span class="notification-tile__bold">
                    {{ notification.payload.document_name }} </span
                >) do swojego wniosku
                <span class="notification-tile__bold">{{
                    notification.payload.application_name
                }}</span>
            </template>
            <template
                v-else-if="
                    notification.code == $store.state.NOTIFICATION_CODES.APP_DOCUMENT_FILE_UPLOADED
                "
            >
                <span class="notification-tile__bold">{{ debtor.name }}</span> dodał
                <span
                    class="notification-tile__bold"
                    v-if="notification.combined_notifications_number > 0"
                >
                    {{
                        $store.getters["pluralize"]({
                            n: notification.combined_notifications_number + 1,
                            values: ["", "1 plik", "{n} pliki", "{n} plików"]
                        })
                    }}
                </span>
                <template v-else>
                    plik
                    <span
                        class="notification-tile__bold"
                        style="word-break: break-word !important"
                    >
                        {{ notification.payload.file_name }}
                    </span>
                </template>
                do Dokumentu
                <span class="notification-tile__bold">
                    {{ notification.payload.document_name || "-" }}
                </span>
            </template>
            <template
                v-else-if="
                    notification.code == $store.state.NOTIFICATION_CODES.APP_DOCUMENTS_EXPORTED
                "
            >
                Eksport dokumentów dla Wniosku
                <span class="notification-tile__bold">{{
                    notification.payload.application_name
                }}</span>
                został ukończony, pliki są gotowe do pobrania.
            </template>
            <div class="mt-1 text-right text-caption grey--text">
                {{ formatDateWithTime(new Date(notification.c_date)) }}
            </div>
        </div>
    </router-link>
</template>

<script>
import formatDateWithTime from "../helpers/format-date-with-time";

export default {
    props: {
        notification: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            debtor_loaded: false,

            DEBTOR_IMAGE_CODES: [
                this.$store.state.NOTIFICATION_CODES.APP_CUSTOM_DOCUMENT_CREATED,
                this.$store.state.NOTIFICATION_CODES.APP_DOCUMENT_FILE_UPLOADED,
                this.$store.state.NOTIFICATION_CODES.APP_STATUS_CHANGE
            ],

            formatDateWithTime
        };
    },

    computed: {
        to() {
            if (
                [
                    this.$store.state.NOTIFICATION_CODES.APP_CUSTOM_DOCUMENT_CREATED,
                    this.$store.state.NOTIFICATION_CODES.APP_DOCUMENT_FILE_UPLOADED,
                    this.$store.state.NOTIFICATION_CODES.APP_STATUS_CHANGE,
                    this.$store.state.NOTIFICATION_CODES.APP_DOCUMENTS_EXPORTED
                ].indexOf(this.notification.code) !== -1
            ) {
                return {
                    name: "applications__edit",
                    params: {
                        id: this.notification.application
                    }
                };
            }
            return "/";
        },
        debtor_image_notification() {
            return this.DEBTOR_IMAGE_CODES.indexOf(this.notification.code) !== -1;
        },
        debtor() {
            const U = this.$store.getters["users/getUser"](this.notification.payload.debtor);
            if (!U)
                return {
                    name: "Użytkownik usunięty"
                };
            return U;
        }
    },

    methods: {
        async markAsRead() {
            if (this.notification.is_read) return;
            try {
                await this.$axios.$post(`/notifications/mark-as-read`, {
                    notifications: [this.notification._id]
                });
            } catch (err) {
                console.error(err);
            }
        },

        async loadUser(user) {
            if (!this.$store.getters["users/userIsFetched"](user)) {
                await this.$store.dispatch("users/fetchUser", user);
            }
        }
    },

    async mounted() {
        if (this.debtor_image_notification) {
            await this.loadUser(this.notification.payload.debtor);
            this.debtor_loaded = true;
        }
    }
};
</script>
