import { io } from "socket.io-client";
import store from "../store";
import { playNewNotificationSound } from "../helpers/sounds";

export default {
    install(Vue) {
        Vue.prototype.$io = io(store.state.API_BASE_URL, {
            transports: ["websocket"],
            autoConnect: false
        });

        Vue.prototype.$io.on("connect", async () => {
            await store.dispatch("auth/socketJoinRooms");
        });
        Vue.prototype.$io.on("disconnect", async reason => {
            store.state.auth.socket_room_joined = false;
            console.log(`[Socket.io] Disconnected (${reason})`);
        });

        Vue.prototype.$io.on("User.activity_status_changed", data => {
            store.commit("users/setUserActivityStatus", data);
        });

        Vue.prototype.$io.on("ChatMessage.created", async data => {
            const UID = store?.state?.auth?.user?._id;
            if (!UID || data.creator == UID) return; // odrzucamy swoje wiadomości

            let accepted = false;
            // Sprawdzamy, czy mamy załadowany czat dla danej wiadomości - jak tak to po prostu wpada
            const CHAT = store.getters["chats/getChat"](data.application);
            if (CHAT) {
                store.commit("chats/insertChatMessage", data);
                accepted = true;
            }
            // Jeżeli nie mamy czatu, ale jesteśmy przypisanym do wiadomości prawnikiem, to pobieramy czat i wiadomość też wpada
            else if (data.lawyer == UID) {
                try {
                    const r = await Vue.prototype.$axios.$get(`/applications/` + data.application);
                    store.commit(
                        "chats/insertChat",
                        store.getters["chats/getChatInsertObjectFromApplicationObject"](
                            r.application
                        )
                    );

                    store.commit("chats/insertChatMessage", data);
                    accepted = true;
                } catch (err) {
                    console.error(err);
                }
            }

            if (accepted) {
                try {
                    // oznaczenie nowododanej wiadomości jako dostarczonej
                    await Vue.prototype.$axios.$put(`/chat-messages/batch/status`, {
                        status: "delivered",
                        application: data.application,
                        ids: [data._id]
                    });

                    // jeżeli czat jest aktywny, to od razu też jako odczytaną
                    if (store.state.chats.focused_chats.indexOf(data.application) !== -1) {
                        await Vue.prototype.$axios.$put(`/chat-messages/batch/status`, {
                            status: "read",
                            application: data.application,
                            ids: [data._id]
                        });
                        store.commit("chats/updateChat", {
                            id: data.application,
                            data: {
                                unread_count: 0,
                                last_mark_as_read_id: data._id
                            }
                        });
                    } else {
                        // jeżeli czat nie jest otwarty, to dajemy sygnał dźwiękowy
                        playNewNotificationSound(
                            store.state.auth?.user?.settings?.notifications_sound_volume || 1
                        );
                        const CHAT = store.getters["chats/getChat"](data.application);
                        store.commit("chats/updateChat", {
                            id: data.application,
                            data: {
                                unread_count: CHAT.unread_count + 1
                            }
                        });
                    }
                } catch (err) {
                    console.error(err);
                }

                // zawsze aktualizujemy w obiekcie czatu obiekt last_message
                store.commit("chats/updateChat", {
                    id: data.application,
                    data: {
                        last_message: data
                    }
                });
            }
        });

        Vue.prototype.$io.on("ChatMessage.status_updated", async data => {
            // console.log(data);
            if (data.update_mode === "by_id") {
                for (let i = 0; i < data.ids.length; i++) {
                    const M = store.getters["chats/getChatMessage"](data.ids[i]);
                    if (!M) continue;

                    store.commit("chats/updateChatMessage", {
                        id: M._id,
                        data: data.updated_data
                    });
                }
            } else if (data.update_mode === "by_c_date") {
                for (let i = 0; i < store.state.chats.chat_messages.length; i++) {
                    if (
                        store.state.chats.chat_messages[i].application != data.application ||
                        // (data.creator == "NOT_USER" &&
                        //     store.state.chats.chat_messages[i].creator == store.state.auth.user._id) ||
                        (data.creator == "USER" &&
                            store.state.chats.chat_messages[i].creator == store.state.auth.user._id)
                    ) {
                        continue;
                    }

                    store.state.chats.chat_messages.splice(i, 1, {
                        ...store.state.chats.chat_messages[i],
                        ...data.updated_data
                    });
                }
            }
        });
    }
};
