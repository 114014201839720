<template>
    <div
        class="chat-message"
        :class="{
            'chat-message--right': createdByCurrentUser,
            'chat-message--ficg': isFirstInCreatorGroup,
            'chat-message--licg': isLastInCreatorGroup
        }"
        v-intersect.once="onIntersect"
    >
        <!-- BLOK WIADOMOŚC -->
        <div
            class="chat-message__content"
            :class="{
                'chat-message__content--padded': !createdByCurrentUser
            }"
        >
            <!-- TILE WIADOMOŚCI -->
            <div
                class="chat-message__msg"
                :class="{ 'chat-message__msg--mine': createdByCurrentUser }"
            >
                <div
                    class="chat-message__msgc"
                    :class="{
                        'chat-message__msgc--mine': createdByCurrentUser,
                        'chat-message__msgc--not-mine': !createdByCurrentUser,
                        'chat-message__msgc--first-in-group': isFirstInCreatorGroup,
                        'chat-message__msgc--last-in-group': isLastInCreatorGroup
                    }"
                    @click="$device.mobile ? (mobile_details = !mobile_details) : false"
                    :title="formatDateWithTime(new Date(item.c_date))"
                >
                    <div
                        v-html="item.message.nl2br()"
                        v-if="item.message && item.message.length > 0"
                    ></div>
                    <div
                        v-else-if="item.attachment"
                        class="chat-message-file"
                        @click.stop="openAttachmentPreview"
                    >
                        <div class="chat-message-file__icon">
                            <div
                                class="chat-message-file__phicon"
                                v-if="file_thumb_url === null"
                            >
                                <v-icon size="24">mdi-file</v-icon>
                            </div>
                            <v-img
                                v-else
                                :src="file_thumb_url"
                                class="chat-message-file__image"
                            />
                        </div>
                        <div
                            class="chat-message-file__details"
                            :title="item.attachment.file_name"
                        >
                            {{ item.attachment.file_name }}
                            <div class="chat-message-file__size text-caption grey--text">
                                {{
                                    uploading_file
                                        ? "Przesyłanie pliku..."
                                        : formatFileSize(item.attachment.file_size)
                                }}
                            </div>
                            <v-progress-linear
                                v-if="uploading_file"
                                height="4"
                                color="primary"
                                class="mt-1"
                                :value="item.upload_progress"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <!-- PRZYCISK POBIERANIA ZAŁĄCZNIKA -->
            <div
                class="chat-message__attdownload"
                :class="{
                    'chat-message__attdownload--left': createdByCurrentUser,
                    'chat-message__attdownload--right': !createdByCurrentUser
                }"
                v-if="item.attachment != undefined"
            >
                <v-btn
                    icon
                    color="grey lighten-1"
                    @click="downloadAttachment"
                    :loading="downloading_attachment"
                >
                    <v-icon>mdi-download</v-icon>
                </v-btn>
            </div>

            <!-- USER AVATAR -->
            <div
                class="chat-message__user"
                v-if="!createdByCurrentUser && item.hide_avatar !== true && isLastInCreatorGroup"
            >
                <v-avatar
                    size="28"
                    color="primary"
                    :title="message_sender ? message_sender.name : ''"
                >
                    <v-progress-circular
                        color="white"
                        width="1"
                        size="12"
                        indeterminate
                        v-if="!message_sender"
                    ></v-progress-circular>
                    <template v-else>
                        <div
                            class="text-center text-caption text-uppercase white--text"
                            style="user-select: none"
                            v-if="
                                !message_sender.profile_image ||
                                !message_sender.profile_image.s3_file_url_128px ||
                                message_sender.profile_image.s3_file_url_128px === ''
                            "
                        >
                            {{ $store.getters["users/getInitialsFromName"](message_sender.name) }}
                        </div>
                        <v-img
                            v-else
                            :src="message_sender.profile_image.s3_file_url_128px"
                            width="100%"
                            height="100%"
                        ></v-img>
                    </template>
                </v-avatar>
            </div>

            <!-- STATUS INDICATOR -->
            <div
                class="chat-message__indicator"
                :class="{
                    'chat-message__indicator--right': createdByCurrentUser,
                    'chat-message__indicator--left': !createdByCurrentUser
                }"
            >
                <span
                    v-if="createdByCurrentUser && item.status == 'created'"
                    title="Wysyłanie"
                >
                    <v-icon
                        size="16"
                        color="secondary"
                        >mdi-circle-outline</v-icon
                    >
                </span>
                <span
                    v-else-if="createdByCurrentUser && item.status == 'sent'"
                    title="Wiadomość wysłana"
                >
                    <v-icon
                        size="16"
                        color="secondary"
                        >mdi-check-circle-outline</v-icon
                    >
                </span>
                <span
                    v-else-if="createdByCurrentUser && item.status == 'delivered'"
                    title="Wiadomość dostarczona"
                >
                    <v-icon
                        size="16"
                        color="secondary"
                        >mdi-check-circle</v-icon
                    >
                </span>
                <span
                    v-else-if="
                        createdByCurrentUser &&
                        item.status == 'read' &&
                        isLastInCreatorGroup &&
                        item.newest_read_of_its_creator
                    "
                    :title="'Wyświetlono - ' + formatDateWithTime(new Date(item.read_date))"
                >
                    <v-avatar
                        size="16"
                        color="primary"
                        :title="chat_recipient ? chat_recipient.name : ''"
                    >
                        <v-progress-circular
                            color="white"
                            width="1"
                            size="8"
                            indeterminate
                            v-if="!chat_recipient"
                        ></v-progress-circular>
                        <template v-else>
                            <div
                                class="text-center text-uppercase white--text font-weight-light"
                                style="user-select: none; font-size: 8px; line-height: 8px"
                                v-if="
                                    !chat_recipient.profile_image ||
                                    !chat_recipient.profile_image.s3_file_url_128px ||
                                    chat_recipient.profile_image.s3_file_url_128px === ''
                                "
                            >
                                {{
                                    $store.getters["users/getInitialsFromName"](chat_recipient.name)
                                }}
                            </div>
                            <v-img
                                v-else
                                :src="chat_recipient.profile_image.s3_file_url_128px"
                                width="100%"
                                height="100%"
                            ></v-img>
                        </template>
                    </v-avatar>
                </span>
            </div>
        </div>

        <!-- MOBILE DATE -->
        <v-expand-transition>
            <div v-if="mobile_details">
                <div
                    class="chat-message__msgd text-caption grey--text text-right"
                    :class="{ 'chat-message__msgd--padded': !createdByCurrentUser }"
                >
                    {{ formatDateWithTime(new Date(item.c_date)) }}
                </div>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
import formatDateWithTime from "../helpers/format-date-with-time";
import { calcFileSize } from "@/helpers/calc-file-size";
import waitForMs from "@/helpers/wait-for-ms";
import EventBus from "@/helpers/event-bus";

export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        recipient: {
            type: String,
            required: true
        },
        currentUser: {
            type: String,
            required: true
        },
        isLastInCreatorGroup: {
            type: Boolean,
            default: false
        },
        isFirstInCreatorGroup: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            mobile_details: false,

            downloading_attachment: false,

            file_thumb_url: null,
            file_thumb_fetched: false,

            formatDateWithTime
        };
    },

    computed: {
        createdByCurrentUser() {
            return this.item.creator == this.currentUser;
        },
        message_sender() {
            return this.$store.getters["users/getUser"](this.item.creator);
        },
        chat_recipient() {
            return this.$store.getters["users/getUser"](this.recipient);
        },
        uploading_file() {
            return this.item.status == "created" && this.item.upload_progress != undefined;
        }
    },

    methods: {
        formatFileSize(fsize) {
            return calcFileSize(fsize);
        },

        async downloadAttachment() {
            if (this.downloading_attachment) return;
            this.downloading_attachment = true;

            await waitForMs(250);
            const FILE_URL = await this.getMessageAttachmentFileUrl();

            try {
                const r = await this.$axios.$get(FILE_URL, {
                    responseType: "blob",
                    transformRequest: (data, headers) => {
                        delete headers.authorization;
                        delete headers["x-authorization"];
                        return data;
                    },
                    headers: {
                        "Cache-Control": "no-cache",
                        "Pragma": "no-cache",
                        "Expires": "0"
                    }
                });

                const FURL = window.URL.createObjectURL(r);
                const A = document.createElement("a");
                A.setAttribute("href", FURL);
                A.setAttribute("download", this.item.attachment.file_name);
                document.body.appendChild(A);
                A.click();
                document.body.removeChild(A);
                window.URL.revokeObjectURL(FURL);
            } catch (err) {
                console.error(err);
            }

            this.downloading_attachment = false;
        },

        async downloadFilePreview() {
            const FURL = await this.getMessageAttachmentFileUrl("thumb");
            if (FURL) {
                this.file_thumb_url = FURL;
            }
            this.file_thumb_fetched = true;
        },

        async getMessageAttachmentFileUrl(file_type = "source_file") {
            try {
                const r = await this.$axios.$get(
                    `/files/${this.item.attachment._id}/signed-url/?file_type=${file_type}`,
                    {
                        supress_errors: true
                    }
                );

                return r.url;
            } catch (err) {
                console.error(err);
            }
        },

        onIntersect(entries, observer, isIntersecting) {
            if (
                isIntersecting &&
                this.item.attachment != undefined &&
                this.item.attachment._id != "dull" &&
                !this.file_thumb_fetched
            ) {
                this.downloadFilePreview();
            }
        },

        async openAttachmentPreview() {
            const FURL = await this.getMessageAttachmentFileUrl();
            if (FURL) {
                EventBus.$emit("open-file-preview", {
                    file_type: this.item.attachment.file_display_type,
                    url: FURL
                });
            } else {
                this.$message({
                    type: "error",
                    msg: "Nie udało się załadować podglądu pliku"
                });
            }
        }
    },

    created() {
        if (!this.createdByCurrentUser) {
            this.$store.dispatch("users/fetchUser", this.item.creator);
        }
        this.$store.dispatch("users/fetchUser", this.recipient);
    }
};
</script>
