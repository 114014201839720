<template>
    <div class="chat-message-input">
        <div class="chat-message-input__lactions">
            <input
                type="file"
                style="display: none"
                ref="attachment_input"
                @change="onAttachemntInputChange"
            />
            <v-btn
                text
                color="primary"
                class="px-0"
                title="Załącz plik(i) - dozwolone są tylko JPG, PNG i PDF do 7MB"
                @click="$refs.attachment_input.click()"
            >
                <v-icon size="24">mdi-paperclip</v-icon>
            </v-btn>
        </div>
        <div class="chat-message-input__txtarea">
            <textarea
                name="chat-message"
                ref="chat_message"
                rows="1"
                placeholder="Twoja wiadomość"
                @input="resizeTextarea"
                :style="textarea_styles"
                v-model="textarea_value"
                @keydown.enter="onTextareaEnter"
                @focus="onChatFocus"
                @blur="onChatBlur"
            ></textarea>
        </div>
        <div class="chat-message-input__ractions">
            <CustomEmojiPicker
                @emoji="insertEmojiInChatMessage"
                style="display: flex"
            />
            <v-btn
                text
                color="success"
                class="px-0"
                :disabled="textarea_value.length === 0"
                @click="sendMessage()"
            >
                <v-icon
                    name="send"
                    size="28"
                    >mdi-send</v-icon
                >
            </v-btn>
        </div>
    </div>
</template>

<script>
import CustomEmojiPicker from "./CustomEmojiPicker.vue";
import EventBus from "@/helpers/event-bus";

export default {
    components: {
        CustomEmojiPicker
    },

    props: {
        chatId: {
            type: String,
            required: true
        },
        focusable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            textarea_styles: {
                "height": "40px",
                "overflow-y": "auto"
            },
            textarea_max_rows: 4,
            textarea_value: "",
            sending_message: false
        };
    },
    methods: {
        resizeTextarea() {
            this.textarea_styles.height = "auto";
            this.$nextTick(() => {
                const realHeight = this.$refs["chat_message"].scrollHeight;
                const PADDING_SIZE = 16;
                const LINE_HEIGHT = 24;
                // console.log(realHeight);
                if (realHeight - PADDING_SIZE > LINE_HEIGHT * this.textarea_max_rows) {
                    this.textarea_styles.height = LINE_HEIGHT * this.textarea_max_rows + "px";
                    this.textarea_styles["overflow-y"] = "auto";
                } else {
                    this.textarea_styles.height = realHeight + "px";
                    this.textarea_styles["overflow-y"] = "hidden";
                }
            });
        },
        onTextareaEnter(e) {
            if (e.shiftKey) return;
            e.preventDefault();
            this.sendMessage();
        },

        // ATTACHMENT HANDLING
        handleRemoteFileDropEvent(ev) {
            if (ev.chat != this.chatId) return;
            this.handleAttachmentFiles(ev.files);
        },
        onAttachemntInputChange(e) {
            if (!e || !e.target || !e.target.files || e.target.files.length === 0) return;
            this.handleAttachmentFiles([e.target.files[0]]);
            this.$refs.attachment_input.value = "";
        },
        handleAttachmentFiles(files = []) {
            const ALLOWED_MIMETYPES = [
                "application/pdf",
                "image/png",
                "image/jpeg",
                "image/pjpeg",
                "image/jfif"
            ];
            const ALLOWED_EXTENSIONS = ["pdf", "png", "jpg", "jpeg"];
            const INVALID_TYPES = [];
            const TOO_LARGE = [];
            for (let i = 0; i < files.length; i++) {
                let ext = files[i].name.split(".");
                ext = ext[ext.length - 1].toLowerCase();
                if (
                    ALLOWED_EXTENSIONS.indexOf(ext) === -1 ||
                    ALLOWED_MIMETYPES.indexOf(files[i].type) === -1
                ) {
                    INVALID_TYPES.push(files[i].name);
                    continue;
                }
                if (files[i].size > 7340032) {
                    TOO_LARGE.push(files[i].name);
                    continue;
                }
                // jak git, to sendMessage
                // console.log("should send " + files[i].name);
                this.sendMessage(files[i]);
            }
            if (INVALID_TYPES.length > 0) {
                this.$message({
                    type: "error",
                    msg: `Dozwolone są tylko pliki JPG, PNG i PDF. Wybrane pliki (${INVALID_TYPES.join(
                        ", "
                    )}) nie mogły zostać przesłane.`
                });
            }
            if (TOO_LARGE.length > 0) {
                this.$message({
                    type: "error",
                    msg: `Maksymalny rozmiar pojedynczego pliku to 7MB. Wybrane pliki (${TOO_LARGE.join(
                        ", "
                    )}) nie mogły zostać przesłane.`
                });
            }
        },

        // MESSAGE SENDING
        async sendMessage(attachment = null) {
            // if (this.sending_message) return;
            let msg_type = "text";
            if (attachment != null) msg_type = "file";
            const CHAT = this.$store.getters["chats/getChat"](this.chatId);
            const AXIOS_ADDITIONAL_CONFIG = {};
            // walidacja + tworzenie lokalnego obiektu
            const MSG = {
                _id:
                    "local_" +
                    msg_type +
                    (Date.now() + Math.round(Math.random() * 1000)).toString(16),
                application: this.chatId,
                creator: this.$store.state.auth.user._id,
                debtor: CHAT ? CHAT.application.debtor : null,
                status: "created",
                c_date: Date.now()
            };
            const FD = new FormData();
            FD.append("application", MSG.application);
            if (msg_type == "text") {
                const TXT_MSG = this.textarea_value.trim();
                if (TXT_MSG.length === 0) {
                    return this.$message({
                        type: "error",
                        msg: "Nie można przesłać pustej wiadomości"
                    });
                } else if (TXT_MSG.length > 4095) {
                    return this.$message({
                        type: "error",
                        msg: "Maksymalna długość wiadomości to 4095 znaków"
                    });
                }
                MSG.message = TXT_MSG;
                MSG.message_type = "text_message";
                FD.append("message", MSG.message);
            } else {
                FD.append("file", attachment);
                MSG.attachment = {
                    _id: "dull",
                    file_name: attachment.name,
                    file_mimetype: attachment.type,
                    file_display_type: "unknown",
                    file_size: attachment.size
                };
                AXIOS_ADDITIONAL_CONFIG.onUploadProgress = progressEvent => {
                    this.$store.commit("chats/updateChatMessage", {
                        id: MSG._id,
                        data: {
                            upload_progress: Math.ceil(
                                (progressEvent.loaded / progressEvent.total) * 100
                            )
                        }
                    });
                };
            }
            // this.sending_message = true;
            this.$store.commit("chats/insertChatMessage", MSG);
            this.textarea_value = "";
            this.$nextTick(() => {
                this.resizeTextarea();
            });
            try {
                const M = await this.$axios.$post("/chat-messages", FD, {
                    timeout: 120 * 1000,
                    ...AXIOS_ADDITIONAL_CONFIG
                });
                setTimeout(() => {
                    this.$store.commit("chats/updateChatMessage", {
                        id: MSG._id,
                        data: M.chat_message
                    });
                }, 100);
            } catch (err) {
                this.$store.commit("chats/removeChatMessage", MSG._id);
                console.error(err);
            }
            // this.sending_message = false;
        },

        // EMOJI PICKER
        insertEmojiInChatMessage(emoji) {
            const el = this.$refs["chat_message"];

            let cursorPos = el.selectionEnd;
            this.textarea_value =
                this.textarea_value.substring(0, cursorPos) +
                emoji +
                this.textarea_value.substring(cursorPos);

            // Get new cursor position
            cursorPos += emoji.length;
            this.$nextTick(() => {
                el.setSelectionRange(cursorPos, cursorPos);
                el.focus();
            });
        },

        // FOCUS / BLUR EVENTS
        onChatFocus() {
            if (!this.focusable) return;
            this.$store.commit("chats/markChatAsFocused", this.chatId);
            this.$store.dispatch("chats/markChatMessagesAsRead", {
                chat: this.chatId
            });
        },
        onChatBlur() {
            if (!this.focusable) return;
            this.$store.commit("chats/markChatAsBlured", this.chatId);
        }
    },

    mounted() {
        EventBus.$on("chat-window-file-dropped", this.handleRemoteFileDropEvent);
    },

    beforeDestroy() {
        EventBus.$off("chat-window-file-dropped", this.handleRemoteFileDropEvent);
    }
};
</script>
