function createResolvedPromise() {
    return new Promise(resolve => resolve());
}

export default {
    namespaced: true,

    state: () => ({
        chats: [], // pobrane konwersacje
        chat_messages: [], // wszystkie wiadomości ze wszystkich czatów

        // wskaźniki pomocniki do ładowania konwersacji
        chats_loading: false,
        chats_loaded: false,
        chats_current_page: 0,
        chats_number_of_pages: 1,
        chats_number_of_items: 0,

        chats_fetching_promise: null, // handle na uniwersalną obietnicę pobierania czatów (dzięki temu zawsze można zrobić await na fetchChats)
        opened_chats_popup_windows: [], // tablica z ID otwartych w formie popup czatów
        focused_chats: [] // tablica z ID czatów, które mają focus (są aktywne) - raczej zawsze będzie w niej tylko jeden element
    }),

    getters: {
        getChats(state) {
            return state.chats.sort((a, b) => {
                if (!a.last_message) return -1;
                if (!b.last_message) return 1;
                return b.last_message.c_date - a.last_message.c_date;
            });
        },
        getChat: state => id => {
            return state.chats.find(it => it._id == id);
        },
        unreadChatsCount(state) {
            return state.chats.reduce((acc, item) => {
                return acc + (item.unread_count > 0 ? 1 : 0);
            }, 0);
        },
        getChatInsertObjectFromApplicationObject: () => application => {
            return {
                _id: application._id,
                last_message: null,
                unread_count: 0,
                application: {
                    _id: application._id,
                    number: application.number,
                    service_provider: application.service_provider,
                    debtor: application.debtor
                }
            };
        },

        // CHAT MESSAGES
        getChatMessages: state => chat_id => {
            return state.chat_messages
                .filter(it => it.application == chat_id)
                .sort((a, b) => b.c_date - a.c_date);
        },
        getChatMessage: state => id => {
            return state.chat_messages.find(it => it._id == id);
        }
    },

    mutations: {
        insertChat(state, chats = []) {
            if (!Array.isArray(chats)) {
                chats = [chats];
            }

            const A = [];
            for (let i = 0; i < chats.length; i++) {
                const ix = state.chats.findIndex(it => it._id == chats[i]._id);
                if (ix === -1) {
                    A.push({
                        ...chats[i],
                        chm_fetching: false,
                        chm_fetched: false,
                        chm_fetching_promise: null,
                        chm_current_page: 0,
                        chm_number_of_pages: 1,
                        chm_number_of_items: 0,
                        last_mark_as_read_id: null
                    });
                }
            }

            state.chats = state.chats.concat(A);
        },
        updateChat(state, upd_obj) {
            if (!upd_obj.id || !upd_obj.data) return;

            const ix = state.chats.findIndex(it => it._id == upd_obj.id);
            if (ix !== -1) {
                state.chats.splice(ix, 0, {
                    ...state.chats.splice(ix, 1)[0],
                    ...upd_obj.data
                });
            }
        },

        markChatAsFocused(state, chat_id) {
            const ix = state.focused_chats.indexOf(chat_id);
            if (ix === -1) {
                state.focused_chats.push(chat_id);
            }
        },
        markChatAsBlured(state, chat_id) {
            const ix = state.focused_chats.indexOf(chat_id);
            if (ix !== -1) {
                state.focused_chats.splice(ix, 1);
            }
        },

        // CHAT MESSAGES
        insertChatMessage(state, message = []) {
            if (!Array.isArray(message)) message = [message];

            const A = [];
            for (let i = 0; i < message.length; i++) {
                const ix = state.chat_messages.findIndex(it => it._id == message[i]._id);
                if (ix === -1) {
                    A.push(message[i]);
                }
            }

            if (A.length > 0) {
                state.chat_messages = state.chat_messages.concat(A);
            }
        },
        updateChatMessage(state, upd_obj) {
            if (!upd_obj.id || !upd_obj.data) return;

            const ix = state.chat_messages.findIndex(it => it._id == upd_obj.id);
            if (ix !== -1) {
                state.chat_messages.splice(ix, 0, {
                    ...state.chat_messages.splice(ix, 1)[0],
                    ...upd_obj.data
                });
            }
        },
        removeChatMessage(state, id) {
            const ix = state.chat_messages.findIndex(it => it._id == id);
            if (ix !== -1) {
                state.chat_messages.splice(ix, 1);
            }
        }
    },

    actions: {
        fetchChats({ commit, state }) {
            if (state.chats_loading) {
                if (state.chats_fetching_promise != null) return state.chats_fetching_promise;
                return createResolvedPromise();
            }

            if (state.chats_current_page >= state.chats_number_of_pages) {
                return createResolvedPromise();
            }

            state.chats_loading = true;
            state.chats_fetching_promise = new Promise(async (resolve, reject) => {
                try {
                    const r = await this._vm.$axios.$get(
                        `/chats/?page=${state.chats_current_page + 1}&items_per_page=24`
                    );

                    commit("insertChat", r.chats);

                    state.chats_current_page = r.pagination.current_page;
                    state.chats_number_of_pages = r.pagination.number_of_pages;
                    state.chats_number_of_items = r.pagination.number_of_items;

                    state.chats_loading = false;
                    state.chats_loaded = true;
                    return resolve();
                } catch (err) {
                    state.chats_loading = false;
                    return reject(err);
                }
            });
            return state.chats_fetching_promise;
        },

        fetchChatMessages({ commit, state, getters }, chat_id) {
            const CHAT = getters.getChat(chat_id);
            if (!CHAT) throw new Error("Tried to fetch messages for non-existing Chat");

            if (CHAT.chm_fetching) {
                if (CHAT.chm_fetching_promise != null) return CHAT.chm_fetching_promise;
                return createResolvedPromise();
            }

            if (CHAT.chm_current_page >= CHAT.chm_number_of_pages) {
                return createResolvedPromise();
            }

            const P = new Promise(async (resolve, reject) => {
                try {
                    const r = await this._vm.$axios.$get(
                        `/chat-messages/?page=${
                            CHAT.chm_current_page + 1
                        }&items_per_page=32&application=${CHAT._id}`
                    );

                    commit("insertChatMessage", r.chat_messages);

                    commit("updateChat", {
                        id: CHAT._id,
                        data: {
                            chm_current_page: r.pagination.current_page,
                            chm_number_of_pages: r.pagination.number_of_pages,
                            chm_number_of_items: r.pagination.number_of_items,
                            chm_fetched: true,
                            chm_fetching: false,
                            chm_fetching_promise: null
                        }
                    });

                    if (r.chat_messages.length > 0 && r.pagination.current_page === 1) {
                        await this._vm.$axios.$put(`/chat-messages/batch/status`, {
                            status: "delivered",
                            to_id: r.chat_messages[0]._id
                        });
                    }

                    return resolve();
                } catch (err) {
                    state.chats_loading = false;
                    return reject(err);
                }
            });

            commit("updateChat", {
                id: CHAT._id,
                data: {
                    chm_fetching: true,
                    chm_fetching_promise: P
                }
            });

            return P;
        },

        // Metoda (quasi manager) do oznaczania wiadomości z danego czatu jako odczytanych
        async markChatMessagesAsRead({ commit, state, getters }, data) {
            if (!data.chat) return console.error("You need to provide CHAT_ID");

            const IS_LAWYER = this.state.auth?.user?.role === "lawyer";

            const CHAT = getters.getChat(data.chat);
            if (!CHAT) return console.error("Could not find Chat with given ID");

            // Metoda działa dwutorowo (ale zawsze metodą to_id):
            // 1. Jeżeli przekazano chat_message_id, to sprawdzamy, czy jest to aktualne i jak tak to wysyłamy request
            // 2. Jeżeli nieprzekazano chat_message_id, to musimy poczekać na pobranie wiadomości i bierzemy wtedy najnowszą nie-naszą
            let should_proceed = false;
            let to_id = null;

            if (data.chat_message_id != undefined) {
                const CHM = getters.getChatMessage(data.chat_message_id);
                if (CHM && CHAT.last_mark_as_read_id != data.chat_message_id) {
                    to_id = data.chat_message_id;
                    should_proceed = true;
                }
            } else {
                // 2.1. Sprawdzamy, czy pobrano już wiadomości z czatu, jak nie to czekamy na ich pobranie
                if (!CHAT.chm_fetched) {
                    await this.dispatch("chats/fetchChatMessages", CHAT._id);
                }
                const MESSAGES = getters.getChatMessages(CHAT._id);
                // 2.2. Jeżeli mimo wszystko nie ma wiadomości, no to nic
                if (MESSAGES.length > 0) {
                    for (let i = 0; i < MESSAGES.length; i++) {
                        if (MESSAGES[i].creator != this.state.auth?.user?._id) {
                            to_id = MESSAGES[i]._id;
                            break;
                        }
                    }
                }

                if (to_id != null && to_id != CHAT.last_mark_as_read_id) {
                    should_proceed = true;
                }
            }

            // 3. Jeżeli mamy co oznaczać, to to robimy
            if (should_proceed) {
                try {
                    if (IS_LAWYER) {
                        await this._vm.$axios.$put(`/chat-messages/batch/status`, {
                            status: "read",
                            to_id
                        });
                    }
                    commit("updateChat", {
                        id: CHAT._id,
                        data: {
                            unread_count: 0,
                            last_mark_as_read_id: to_id
                        }
                    });
                } catch (err) {
                    console.error(err);
                }
            }
        }
    }
};
