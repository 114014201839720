var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{tag:"component",staticClass:"user-tile d-flex align-center text-decoration-none",attrs:{"to":_vm.to}},[(_vm.loaded && _vm.localUser)?[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":_vm.computedAvatarSize,"color":"primary"}},[(
                    _vm.localUser.profile_image && _vm.localUser.profile_image.s3_file_url_128px != ''
                )?_c('v-img',{attrs:{"src":_vm.localUser.profile_image.s3_file_url_128px}}):_c('div',{staticClass:"text-center white--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$store.getters["auth/userInitials"](_vm.localUser))+" ")])],1),_c('div',{staticClass:"text-body-2"},[_c('div',{staticClass:"font-weight-medium",staticStyle:{"color":"#434343"}},[_vm._v(" "+_vm._s(_vm.localUser.name)+" "),(
                        !_vm.hideNameDetails &&
                        _vm.$store.state.auth.user &&
                        _vm.$store.state.auth.user._id == _vm.localUser._id
                    )?_c('span',{staticClass:"font-weight-regular"},[_vm._v(" (Ty) ")]):_vm._e()]),_c('div',{staticClass:"grey--text text-caption",staticStyle:{"margin-top":"-4px"}},[(_vm.detailsKey == 'role')?[_vm._v(" "+_vm._s(_vm.$store.state.USER_ROLE_LABELS[_vm.localUser.role])+" ")]:[_vm._v(" "+_vm._s(_vm.localUser[_vm.detailsKey])+" ")]],2)])]:[_c('v-skeleton-loader',{staticClass:"mr-2",attrs:{"width":_vm.computedAvatarSize,"height":_vm.computedAvatarSize,"type":"avatar"}}),_c('div',[_c('v-skeleton-loader',{attrs:{"width":"90","height":"12","type":"text"}}),_c('v-skeleton-loader',{staticClass:"mt-1",attrs:{"width":"60","height":"8","type":"text"}})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }