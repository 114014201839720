var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-popup-window"},[_c('div',{staticClass:"chat-popup-window__header",class:{
            primary: _vm.mark_as_unread
        }},[_c('div',{staticClass:"chat-popup-window__huser"},[_c('v-badge',{attrs:{"bordered":"","bottom":"","color":"success","dot":"","offset-x":"16","offset-y":"8","value":_vm.debtor_loaded && _vm.debtor.activity_status == 'online'}},[_c('v-avatar',{staticClass:"white--text text-body-2 mr-2",attrs:{"size":"40","color":"secondary"}},[_vm._v(" "+_vm._s(_vm.debtor_initials)+" ")])],1),_c('div',{staticClass:"chat-popup-window__hudet"},[(_vm.header_loading)?[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}})]:[_c('div',{staticClass:"text-body-1",class:{
                            'white--text font-weight-medium': _vm.mark_as_unread
                        }},[_vm._v(" "+_vm._s(_vm.debtor.name)+" ")]),_c('div',{staticClass:"text-caption mt-n1 grey--text",class:{
                            'text--lighten-2': _vm.mark_as_unread
                        }},[_vm._v(" "+_vm._s(_vm.chat.application.service_provider)+"/"+_vm._s(_vm.chat.application.number)+" ")])]],2)],1),_c('div',{staticClass:"chat-popup-window__hactions"},[(_vm.focused)?_c('v-btn',{attrs:{"icon":"","small":"","color":_vm.mark_as_unread ? 'white' : 'secondary'},on:{"click":function($event){_vm.focused = false}}},[_c('v-icon',[_vm._v("mdi-minus")])],1):_c('v-btn',{attrs:{"icon":"","small":"","color":_vm.mark_as_unread ? 'white' : 'secondary'},on:{"click":_vm.maximizeWindow}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"icon":"","small":"","color":_vm.mark_as_unread ? 'white' : 'secondary'},on:{"click":_vm.closeChat}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),(_vm.focused)?_c('div',{staticClass:"chat-popup-window__body"},[_c('ChatFeed',{attrs:{"chat-id":_vm.chatId}}),_c('div',{staticClass:"chat-popup-window__msginp"},[_c('ChatMessageInput',{attrs:{"chat-id":_vm.chatId,"focusable":""}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }