const isToday = someDate => {
    const today = new Date();
    return (
        someDate.getDate() == today.getDate() &&
        someDate.getMonth() == today.getMonth() &&
        someDate.getFullYear() == today.getFullYear()
    );
};

export default function formatDateWithTime(val) {
    const days = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];
    const months = {
        0: "stycznia",
        1: "lutego",
        2: "marca",
        3: "kwietnia",
        4: "maja",
        5: "czerwca",
        6: "lipca",
        7: "sierpnia",
        8: "września",
        9: "października",
        10: "listopada",
        11: "grudnia"
    };

    const year = val.getFullYear();
    const date = val.getDate();
    const time = `${
        val.getHours() <= 9 ? val.getHours().toString().padStart(2, "0") : val.getHours()
    }:${val.getMinutes() <= 9 ? val.getMinutes().toString().padStart(2, "0") : val.getMinutes()}`;

    const dayIndex = val.getDay();
    const dayName = days[dayIndex];

    const monthIndex = val.getMonth();
    const monthName = months[monthIndex];

    if (isToday(val)) {
        return `${time}`;
    }

    if (new Date().getTime() - val.getTime() <= 604800000) {
        return `${dayName}, ${time}`;
    }

    if (year === new Date().getFullYear()) {
        return `${dayName} ${date} ${monthName} ${time}`;
    }

    return `${date} ${monthName} ${year}, ${time} `;
}
