export default {
    namespaced: true,

    state: () => ({
        USERS_DATA: [],
        CURRENTLY_FETCHING: [],

        fetch_debouncer: null,
        fetch_queue: [],
        fetch_loading: false
    }),

    getters: {
        userIsFetched: state => id => {
            return state.USERS_DATA.findIndex(item => item._id == id) !== -1;
        },
        getUser: state => id => {
            return state.USERS_DATA.find(item => item._id == id);
        },

        getInitialsFromName: () => name => {
            if (!name) return "";

            const A = [];
            const SPL = name.split(" ");

            for (let i = 0; i < Math.min(2, SPL.length); i++) {
                A.push(SPL[i][0].toUpperCase());
            }

            return A.join("");
        }
    },

    mutations: {
        addUser(state, user) {
            if (!user) return false;
            if (state.USERS_DATA.findIndex(item => item._id == user._id) === -1) {
                state.USERS_DATA.push(user);
            }
            return true;
        },
        removeUser(state, user_id) {
            const ix = state.USERS_DATA.findIndex(item => item._id == user_id);
            if (ix !== -1) state.USERS_DATA.splice(ix, 1);
        },
        setUserActivityStatus(state, data) {
            if (!data.user_id) return;
            const ix = state.USERS_DATA.findIndex(u => u._id == data.user_id);
            if (ix !== -1) {
                const D = JSON.parse(JSON.stringify(state.USERS_DATA[ix]));
                D.activity_status = data.status;
                if (data.last_seen !== undefined) {
                    D.last_seen = data.last_seen;
                } else {
                    delete D.last_seen;
                }
                state.USERS_DATA.splice(ix, 1, D);
            }
        }
    },

    actions: {
        fetchUser({ commit, state, getters }, user_id) {
            return new Promise(async (resolve, reject) => {
                function checkIfFetched() {
                    if (state.CURRENTLY_FETCHING.indexOf(user_id) !== -1) {
                        setTimeout(checkIfFetched, 500);
                    } else {
                        return resolve(getters.getUser(user_id));
                    }
                }

                if (getters.userIsFetched(user_id)) {
                    return resolve(getters.getUser(user_id));
                } else if (state.CURRENTLY_FETCHING.indexOf(user_id) !== -1) {
                    checkIfFetched();
                } else if (state.fetch_loading) {
                    state.fetch_queue.push(user_id);
                    checkIfFetched();
                } else {
                    state.CURRENTLY_FETCHING.push(user_id);
                    if (state.fetch_debouncer !== null) clearTimeout(state.fetch_debouncer);

                    state.fetch_debouncer = setTimeout(async () => {
                        state.fetch_loading = false;
                        try {
                            const r = await this._vm.$axios.$post(
                                `/users/bulk-fetch/public`,
                                {
                                    ids: state.CURRENTLY_FETCHING
                                },
                                {
                                    supress_errors: true
                                }
                            );

                            for (let i = 0; i < state.CURRENTLY_FETCHING.length; i++) {
                                const ix = r.users.findIndex(
                                    u => u._id == state.CURRENTLY_FETCHING[i]
                                );
                                if (ix !== -1) {
                                    commit("addUser", r.users[ix]);
                                } else {
                                    commit("addUser", {
                                        _id: state.CURRENTLY_FETCHING[i],
                                        email: "brak informacji",
                                        name: "Użytkownik usunięty",
                                        position: "Brak danych",
                                        current_service_provider: "PRAVNA",
                                        status: "deleted",
                                        role: "unknown"
                                    });
                                }
                            }

                            state.CURRENTLY_FETCHING = [];
                        } catch (err) {
                            console.error(err);
                        }
                        state.fetch_loading = false;
                        if (state.fetch_queue.length > 0) {
                            for (let i = 0; i < state.fetch_queue.length; i++) {
                                this.dispatch("users/fetchUser", state.fetch_queue[i]);
                            }
                            state.fetch_queue = [];
                        }
                    }, 500);

                    checkIfFetched();
                }
            });
        }
    }
};
