<template>
    <v-menu
        offset-y
        max-width="380"
        min-width="300"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-badge
                color="error"
                overlap
                offset-x="24"
                offset-y="24"
                :value="unread_count > 0 && items_loaded"
                :content="unread_count > 9 ? '9+' : unread_count"
            >
                <v-btn
                    color="grey"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    title="Twoje konwersacje"
                >
                    <v-icon>mdi-chat</v-icon>
                </v-btn>
            </v-badge>
        </template>
        <template v-slot:default>
            <template v-if="!items_loaded">
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
                <v-skeleton-loader type="list-item-avatar-two-line"></v-skeleton-loader>
            </template>
            <template v-else>
                <div style="max-height: 460px; overflow-y: auto">
                    <div
                        v-if="chats.length > 0"
                        class="white"
                    >
                        <ChatConversation
                            v-for="(chat, index) in chats"
                            :key="chat._id"
                            :chat="chat"
                            redirect-mode="button"
                            :class="{
                                'mb-n1': index < chats.length - 1
                            }"
                        />
                        <div
                            style="height: 1px; margin-top: -1px"
                            v-intersect="onIntersect"
                        ></div>
                        <div
                            class="text-center pt-1"
                            style="overflow: hidden"
                        >
                            <v-progress-circular
                                indeterminate
                                color="primary"
                                size="20"
                                width="2"
                                v-if="items_loading"
                            ></v-progress-circular>
                        </div>
                    </div>
                    <v-list v-else>
                        <v-list-item class="grey--text text-caption">
                            Nie prowadzisz jeszcze żadnej konwersacji
                        </v-list-item>
                    </v-list>
                </div>
                <div class="white px-2 pt-1 pb-2 text-right">
                    <v-divider></v-divider>
                    <v-btn
                        plain
                        x-small
                        style="text-transform: none"
                        class="text-caption px-0 mt-1"
                        :ripple="false"
                        @click.stop="create_chat_dialog = true"
                    >
                        Rozpocznij czat
                    </v-btn>
                </div>
            </template>

            <ChatCreateDialog v-model="create_chat_dialog" />
        </template>
    </v-menu>
</template>

<script>
import ChatCreateDialog from "@/components/ChatCreateDialog";
import ChatConversation from "@/components/ChatConversation";
import chats from "../store/modules/chats";

export default {
    components: {
        ChatCreateDialog,
        ChatConversation
    },

    data() {
        return {
            create_chat_dialog: false,
            create_chat_dialog_loading: false,
            create_chat_dialog_application: false,

            application_search_mv: "",
            application_search_fv: "",
            application_search_loading: false
        };
    },

    computed: {
        unread_count() {
            return this.$store.getters["chats/unreadChatsCount"];
        },
        items_loaded() {
            return this.$store.state.chats.chats_loaded;
        },
        items_loading() {
            return this.$store.state.chats.chats_loading;
        },
        chats() {
            return this.$store.getters["chats/getChats"];
        }
    },

    methods: {
        onIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.$store.dispatch("chats/fetchChats");
            }
        }
    },

    mounted() {
        if (!this.$store.state.initial_layout_set) return;

        this.$store.dispatch("chats/fetchChats");
    },

    beforeDestroy() {}
};
</script>
